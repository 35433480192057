/* eslint-disable max-len */
import '@member/pages/ThesisDetails.css';
import TableThesisHistory from '@components/UIElements/TableThesisHistory';
import UI from '@ui/UI';
import {useEffect, useState} from 'react';
import {Row, Col, Badge, Button, Card, Form, Stack} from 'react-bootstrap';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {BA, useTranslation} from '@util/bachhelpers.js';

function FormThesis(props) {
  const {_} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // useState
  const [legalLabels, setLegalLabels] = useState([]);
  const [origGroupLabel, setOrigGroupLabel] = useState({});
  const [coAuthors, setCoAuthors] = useState([]);
  const [showCoAuthors, setShowCoAuthors] = useState(false);
  const [possibleCoAuthors, setPossibleCoAuthors] = useState([]);
  const [cuTitles, setCuTitles] = useState([]);
  const [showCuTitles, setShowCuTitles] = useState(false);
  const [newCuTitle, setNewCuTitle] = useState('');
  const [titleOrig, setTitleOrig] = useState('');
  const [titleEn, setTitleEn] = useState('');
  const [abstractOrig, setAbstractOrig] = useState('');
  const [abstractEn, setAbstractEn] = useState('');
  const [blockingDescription, setBlockingDescription] = useState('');
  const [showBlockingDetails, setShowBlockingDetails] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const [newKeyword, setNewKeyword] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [xhrLoad, setXhrload] = useState(true);
  const [requiredCheckboxes, setRequiredCheckboxes] = useState([]);
  const [toasts, setToasts] = useState([]);
  const [draftPdf, setDraftPdf] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [correctLanguage, setCorrectLanguage] = useState(false);
  const [checkedLegalLabels, setCheckedLegalLabels] = useState([]);
  // useSelector
  const language = useSelector((state) => state.language);
  const thesis = useSelector((state) => state.thesis);

  // -------- initializing / useEffect --------

  /* initializing variables
     on effect: thesis.tid changes (thesis is loaded)
     - get legal agreement labels
     - set states for form fields
     - conditionally set coauthors
     - conditionally get possible coauthors
     - conditionally set cumulative titles
   */
  useEffect(() => {
    // get legal agreement labels
    getLegalLabels();

    // set states for form fields
    setTitleOrig(thesis.final_draft?.title_original || '');
    setTitleEn(thesis.final_draft?.title_english || '');
    setAbstractOrig(thesis.abstract_original || '');
    setAbstractEn(thesis.abstract_english || '');
    setKeywords(thesis.keywords ? thesis.keywords.split(',') : []);
    setDraftPdf(thesis.final_draft?.draft_pdf?.filename_orig);
    setAttachments(thesis.attachments);
    setShowBlockingDetails(thesis.is_blocking);
    setBlockingDescription(thesis.blocking_description || '');
    setCorrectLanguage(thesis.final_draft?.correct_language || false);

    // conditionally set coauthors
    if (thesis.coauthors?.length > 0) {
      setCoAuthors(thesis.coauthors.map((coAuthor) => {
        return {
          studium: coAuthor.fk_studium_sub,
          student_id: coAuthor.sub_mat_nr,
        };
      }));
      setShowCoAuthors(true);
    }

    // conditionally get possible coauthors
    if (thesis.type?.tid !== 3 && thesis?.studium) getPossCoAuthors();

    // conditionally set cumulative titles
    if (thesis.cumulative_titles?.length > 0) {
      setCuTitles(thesis.cumulative_titles);
      setShowCuTitles(true);
    }

    setCheckedLegalLabels(thesis.final_draft?.legal_agreement?.length > 0 ?
      thesis.final_draft.legal_agreement.map((l) => l.label) : []);
  }, [thesis.tid]);

  useEffect(()=> {
    if (Object.keys(origGroupLabel).length > 0 && thesis.coauthors.length > 0) {
      setCoauthorsLabel();
    }
  }, [origGroupLabel]);

  /* modify (set or reset) legal label for coauthors
     on effect:
     - coAuthors array changes: new coauthor selected or coauthor deleted
     - showCoAuthors changes: checkbox checked or unchecked */
  useEffect(() => {
    if (legalLabels.length > 0) {
      if (coAuthors.length > 0) {
        setCoauthorsLabel();
      }
      if (coAuthors.length === 0) {
        resetCoauthorsLabel();
      }
    }
  }, [coAuthors, showCoAuthors]);

  /* set/reset required checkboxes (only when no xhrLoad)
     on effect:
     - xhrLoad changes: primarily takes effect on loading page
     - showCoAuthors changes: checkbox checked or unchecked
   */
  useEffect(() => {
    if (!xhrLoad) {
      const requiredCheckboxes = [];
      document.querySelectorAll('input[type=checkbox]').forEach((cb) => {
        if (!['request_blocking', 'has_coauthors', 'is_cumulative', 'oenb_loan', 'confirm_check', 'confirm_submit'].includes(cb.id)) {
          requiredCheckboxes.push(`${cb.id}`);
          if (!cb.checked) cb.classList.add('is-invalid');
        }
      });
      setRequiredCheckboxes(requiredCheckboxes);
    }
  }, [xhrLoad, showCoAuthors, language]);

  /* adapt possible coauthors list to only include not already set coauthors when loading page
     also triggers on adding or deleting coauthor but is not performing action then
     - check if already set coauthors are included in possibleCoAuthors list
     - filter out already set coauthors
     on effect:
     - coAuthors change
     - possibleCoAuthors changes
   */
  useEffect(() => {
    // check if already set coauthors are included in possibleCoAuthors list
    if (possibleCoAuthors.length > 0 &&
        coAuthors.length > 0 &&
        possibleCoAuthors.findIndex((pco) => coAuthors.map((co) => co.studium).includes(pco.studium)) !== -1
    ) {
      // filter out already set coauthors
      setPossibleCoAuthors(possibleCoAuthors.filter((pco) => !coAuthors.map((co) => co.studium).includes(pco.studium)));
    };
  }, [coAuthors, possibleCoAuthors]);

  // -------- API calls --------

  /* get legal agreemnent labels */
  const getLegalLabels = () => {
    setXhrload(true);
    BA.ajax({
      url: `/constants/label/index`,
      tmgapi: true,
    }).then((response) => {
      if (response.error) {
        setToasts([{
          variant: 'error',
          code: response.error.code,
          message: _(`app.thesis.response.${response.error.message}`).includes('app.thesis.response') ?
                   _(`app.thesis.response.error`) : _(`app.thesis.response.${response.error.message}`),
          show: true}]);
      } else if (response.data) {
        // filter out allow_publish and oenb_loan labels for type BA
        thesis.type.tid === 1 ? setLegalLabels(response.data.filter((l) => !['allow_publish', 'oenb_loan'].includes(l.label))) : setLegalLabels(response.data);
        setOrigGroupLabel(
            {de: response.data.find((label) => label.tid === 7)?.text || '',
              en: response.data.find((label) => label.tid === 14)?.text || ''});
      }
      setXhrload(false);
    }).catch((e) => {
      dispatch({
        type: 'set_errcode',
        errcode: e,
      });
      setXhrload(false);
    });
  };

  /* get possible coauthors for a study program
     to be displayed in select coauthors element */
  const getPossCoAuthors = () => {
    setXhrload(true);
    BA.ajax({
      url: `/student/get-possible-coauthors/${thesis.studium}`,
      tmgapi: true,
    }).then((response) => {
      if (response.error) {
        setToasts([{
          variant: 'error',
          code: response.error.code,
          message: _(`app.thesis.response.${response.error.message}`).includes('app.thesis.response') ?
                   _(`app.thesis.response.error`) : _(`app.thesis.response.${response.error.message}`),
          show: true}]);
      } else if (response.data) {
        setPossibleCoAuthors(response.data);
      }
      setXhrload(false);
    }).catch((e) => {
      dispatch({
        type: 'set_errcode',
        errcode: e,
      });
      setXhrload(false);
    });
  };

  /* submit thesis data */
  const submitThesisData = () => {
    setShowConfirm(false);
    setXhrload(true);

    const data = {
      thesis_id: thesis.tid,
      studium: thesis.studium,
      thesis_state: thesis.state.tid,
      abstract_original: abstractOrig,
      abstract_english: thesis.language !== 'en' ? abstractEn : abstractOrig,
      keywords: keywords,
      blocking_years: thesis.blocking_years,
      blocking_description: blockingDescription,
      blocking_state: thesis.blocking_state.tid,
      cumulative_titles: cuTitles,
      draft_id: thesis.final_draft.tid,
      title_original: titleOrig,
      title_english: thesis.language !== 'en' ? titleEn : titleOrig,
      legal_agreement: legalLabels.filter((l) => l.language === language).filter((l) =>
        checkedLegalLabels.includes(l.label)).map((l) => {
        return {...l, preamble: _('app.thesis.confirm_legal')};
      }),
      correct_language: correctLanguage,
      coauthors: coAuthors,
      draft_pdf: draftPdf || '',
      aid_list: thesis.attachments.filter((att) => !att.to_publish)?.map((att)=> att.filename_orig).join(','),
      attachments: thesis.attachments.filter((att) => att.to_publish)?.map((att)=> att.filename_orig),
    };

    BA.ajax({
      url: '/thesis/save',
      method: 'POST',
      tmgapi: true,
      contentType: 'application/json',
      data: JSON.stringify(data),
    }).then((response) => {
      if (response.error) {
        const newToasts = [];
        response.error.message.split(';').forEach((message) => {
          if (message !== '') {
            newToasts.push({
              variant: 'error',
              message: _(`app.thesis.response.${message}`).includes('app.thesis.response') ?
                       _(`app.thesis.response.error`) : _(`app.thesis.response.${message}`),
              show: true});
          }
        });
        setToasts(newToasts);
      } else if (response.data) {
        if (thesis.state.tid === 1) {
          setToasts([{
            variant: 'success',
            message: _(`app.thesis.response.success_save_thesis`),
            show: true}]);
        } else {
          navigate(0, {replace: false});
        }
      }
      setXhrload(false);
    }).catch((e) => {
      dispatch({
        type: 'set_errcode',
        errcode: e,
      });
      setXhrload(false);
    });
  };

  // TODO use bachhelpers ajax function
  /* upload file (thesis, attachment, aid list) */
  const uploadFile = (uploadType, file) => {
    const formData = new FormData();
    formData.append('thesis_id', thesis.tid);
    formData.append('file', file);
    let url = process.env.REACT_APP_TMGAPISERVER + '/student/thesis/upload';
    if (uploadType == 'upload_thesis') {
      url += '/draft';
      formData.append('draft_id', thesis.final_draft.tid);
    } else if (uploadType == 'upload_attachments') {
      url += '/attachment';
      formData.append('to_publish', true);
      formData.append('file', file);
    } else if (uploadType == 'upload_aid_list') {
      url += '/attachment';
      formData.append('to_publish', false);
    } else {
      return;
    }
    setXhrload(true);
    fetch(url,
        {
          method: 'PUT',
          headers: {
            'Accept': '*/*', // kein content-type (ich glaube, weil FormData das schon erledigt)!
            'Authorization': 'Bearer ' + sessionStorage.getItem('bachtoken'),
          },
          body: formData,
        })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            setToasts([{
              variant: 'error',
              code: data.error.code,
              message: _(`app.thesis.response.${data.error.message}`).includes('app.thesis.response') ?
                       _(`app.thesis.response.error`) : _(`app.thesis.response.${data.error.message}`),
              show: true}]);
          } else if (data.data) {
            if (uploadType == 'upload_thesis') {
              setDraftPdf(file.name);
              thesis.final_draft.draft_pdf = data.data;
            } else if (uploadType == 'upload_attachments' || uploadType == 'upload_aid_list') {
              const newAttachments = [...attachments, data.data];
              thesis.attachments.push(data.data);
              setAttachments(newAttachments);
            }
          }
          setXhrload(false);
        }).catch((e) => {
          dispatch({
            type: 'set_errcode',
            errcode: e,
          });
          setXhrload(false);
        });
  };

  /* delete attachment */
  const deleteAttachment = (attId) => {
    setXhrload(true);
    const data = {
      thesis_id: thesis.tid,
      attachment_id: parseInt(attId),
    };
    BA.ajax({
      url: '/student/thesis/delete/attachment',
      method: 'DELETE',
      tmgapi: true,
      contentType: 'application/x-www-form-urlencoded',
      data: data,
    }).then((response) => {
      if (response.error) {
        setToasts([{
          variant: 'error',
          code: response.error.code,
          message: _(`app.thesis.response.${response.error.message}`).includes('app.thesis.response') ?
                   _(`app.thesis.response.error`) : _(`app.thesis.response.${response.error.message}`),
          show: true}]);
      }
      setXhrload(false);
    }).catch((e) => {
      dispatch({
        type: 'set_errcode',
        errcode: e,
      });
      setXhrload(false);
    });
  };

  /* delete draft pdf */
  const deleteDraftPdf = () => {
    setXhrload(true);
    const data = {
      thesis_id: thesis.tid,
      draft_id: thesis.final_draft.tid,
      file_id: thesis.final_draft.draft_pdf.tid,
    };

    BA.ajax({
      url: '/student/thesis/delete/draft',
      method: 'DELETE',
      tmgapi: true,
      contentType: 'application/x-www-form-urlencoded',
      data: data,
    }).then((response) => {
      if (response.error) {
        setToasts([{
          variant: 'error',
          code: response.error.code,
          message: _(`app.thesis.response.${response.error.message}`).includes('app.thesis.response') ?
                   _(`app.thesis.response.error`) : _(`app.thesis.response.${response.error.message}`),
          show: true}]);
      } else if (response.data) {
        setDraftPdf('');
      }
      setXhrload(false);
    }).catch((e) => {
      dispatch({
        type: 'set_errcode',
        errcode: e,
      });
      setXhrload(false);
    });
  };

  // -------- Helper functions --------

  /* close toast with error message */
  const handleClose = (i) => {
    const updatedToasts = toasts.map((toast, ix) => {
      if (i === ix) {
        toast.show = false;
      }
      return toast;
    });
    setToasts(updatedToasts);
  };

  /* check if given text is valid Latin 1
     returns null or invalid characters that were found */
  const validateInputField = (text) => {
    // same regex unicode points as in backend
    const latin1Regex = /[^\x00-\x7F\x80-\xFF]+/g;
    const result = text.match(latin1Regex);
    let invalidChars = '';
    if (result) {
      result.forEach((char) => {
        invalidChars += `${char} `;
      });
      return invalidChars;
    }
    return result;
  };

  /* validate a given filename.
     - no leading dot (hidden file)
     - valid are only word characters (a-z, A-Z, 0-9, _) and period, hyphen
     returns null or invalid characters that were found */
  const validateFilename = (filename) => {
    const regexLeadingDot = /^[.]/;
    // leading dot is forbidden
    if (filename.match(regexLeadingDot)) return '.';
    // validate
    const regexValidation = /[^\w.\-]/g;
    const result = filename.match(regexValidation);
    let invalidChars = '';
    if (result) {
      result.forEach((char) => {
        if (char === ' ') invalidChars += '[:space:] ';
        else invalidChars += `${char} `;
      });
      return invalidChars;
    }
    return result;
  };

  /* handle request to save form data without state change */
  const handleSave = (e) => {
    thesis.state.tid = 1;
    submitThesisData();
  };

  /* handle request to submit form data - part 1: validation
     - validate form data
     - if no validation error, show confirm modal */
  const handleSubmitValidate = (e) => {
    const {isError, messages} = checkFormData();
    if (isError) {
      const newToasts = [];
      messages.forEach((message) => {
        newToasts.push({
          variant: 'error',
          message: `${message}`,
          show: true});
      });
      setToasts(newToasts);
    } else {
      setShowConfirm(true);
    }
  };

  /* handle request to submit form data - part 2: process confirm modal
     - validate checkboxes in confirm modal
     - if no validation error, submit thesis data with state change */
  const handleSubmitConfirm = (e) => {
    let isError;
    // check if both boxes in confirm are checked
    [document.querySelector('#confirm_check'), document.querySelector('#confirm_submit')].forEach((checkbox) => {
      if (!checkbox.checked) {
        checkbox.classList.add('is-invalid');
        isError = true;
        return;
      }
    });
    if (!isError) {
      thesis.state.tid = 2;
      submitThesisData();
    } else {
      return;
    }
  };

  /* check form data */
  const checkFormData = () => {
    let isError = false;
    const messages = [];

    // title original
    if (titleOrig.trim().length === '' || titleOrig.trim().length < 10) {
      isError = true;
      messages.push(`${_('app.thesis.title_orig')} ${_('app.thesis.validation.error_input_too_short_10')}`);
      document.querySelector('#title_orig').classList.add('is-invalid');
    }

    // title english only if language not english
    if (thesis.language !== 'en' && (titleEn.trim().length === '' || titleEn.trim().length < 10)) {
      isError = true;
      messages.push(`${_('app.thesis.title_en')} ${_('app.thesis.validation.error_input_too_short_10')}`);
      document.querySelector('#title_en').classList.add('is-invalid');
    }

    // abstracts only for dissertations
    if (thesis.type?.tid === 3) {
      // abstract original
      if (abstractOrig.trim().length === '' || abstractOrig.trim().length < 10) {
        isError = true;
        messages.push(`${_('app.thesis.abstract_orig')} ${_('app.thesis.validation.error_input_too_short_10')}`);
        document.querySelector('#abstract_orig').classList.add('is-invalid');
      }
      // abstract english only if language not english
      if (thesis.language !== 'en' && (abstractEn.trim().length === '' || abstractEn.trim().length < 10)) {
        isError = true;
        messages.push(`${_('app.thesis.abstract_en')} ${_('app.thesis.validation.error_input_too_short_10')}`);
        document.querySelector('#abstract_en').classList.add('is-invalid');
      }
    }

    // required checkboxes
    let uncheckedSum = 0;
    requiredCheckboxes.map((checkbox) => {
      const element = document.querySelector(`#${checkbox}`);
      if (!element.checked) {
        uncheckedSum += 1;
      }
    });
    if (uncheckedSum > 0) {
      isError = true;
      if (uncheckedSum === 1) messages.push(`${uncheckedSum} ${_('app.thesis.validation.error_checkbox')}`);
      if (uncheckedSum > 1) messages.push(`${uncheckedSum} ${_('app.thesis.validation.error_checkboxes')}`);
    }

    // keywords
    if (keywords.length === 0) {
      document.querySelector('#keywords').classList.add('is-invalid');
      isError = true;
      messages.push(_('app.thesis.validation.error_no_keywords'));
    }

    // coauthors
    if (document.querySelector('#has_coauthors')?.checked && coAuthors.length === 0) {
      document.querySelector('#select-coauthors').classList.add('is-invalid');
      isError = true;
      messages.push(_('app.thesis.validation.error_no_coauthors'));
    }

    // cumulative titles
    if (document.querySelector('#is_cumulative')?.checked && cuTitles.length < 2) {
      document.querySelector('#cumulative_titles').classList.add('is-invalid');
      isError = true;
      messages.push(_('app.thesis.validation.error_no_cu_titles'));
    }

    // blocking
    if (document.querySelector('#request_blocking')?.checked) {
      // years
      if (!thesis.blocking_years) {
        document.querySelector('#blocking_years').classList.add('is-invalid');
        isError = true;
        messages.push(_('app.thesis.validation.error_no_blocking_years'));
      }
      // description
      if (blockingDescription.trim().length === '' ||
          blockingDescription.trim().length < 50) {
        isError = true;
        messages.push(`${_('app.thesis.reason')} ${_('app.thesis.blocking')} ${_('app.thesis.validation.error_input_too_short_50')}`);
        document.querySelector('#blocking_description').classList.add('is-invalid');
      }
    }

    // upload (thesis required, other uploads optional)
    if (!draftPdf) {
      document.querySelector('#upload_thesis').classList.add('is-invalid');
      isError = true;
      messages.push(_('app.thesis.validation.error_no_thesis'));
    }

    return {isError, messages};
  };

  /* set coauthors label to include the student ids of the current coAuthors */
  const setCoauthorsLabel = () => {
    const newLegalLabels = [...legalLabels];
    const coAuthorIds = coAuthors.map((coAuthor) => coAuthor.student_id.toString().padStart(8, '0'));
    newLegalLabels.find((label) => label.tid === 7).text = origGroupLabel.de.replace('{%CO-AUTHORS%}', coAuthorIds.join(', '));
    newLegalLabels.find((label) => label.tid === 14).text = origGroupLabel.en.replace('{%CO-AUTHORS%}', coAuthorIds.join(', '));
    setLegalLabels(newLegalLabels);
  };

  /* reset coauthors label by including to original variable {%CO-AUTHORS%} */
  const resetCoauthorsLabel =() => {
    const newLegalLabels = [...legalLabels];
    newLegalLabels.find((label) => label.tid === 7).text = origGroupLabel.de;
    newLegalLabels.find((label) => label.tid === 14).text = origGroupLabel.en;
    setLegalLabels(newLegalLabels);
  };

  /* custom function for onChange effects */
  const handleChange = (e) => {
    setToasts([]);
    let result;
    switch (e.target.id) {
      case 'title_orig':
        result = validateInputField(e.target.value);
        if (result) {
          setToasts([{
            variant: 'error',
            message: `${result.length > 1 ?
                        _('app.thesis.validation.error_invalid_characters') :
                        _('app.thesis.validation.error_invalid_character')} ${result}`,
            show: true}]);
        } else {
          setTitleOrig(e.target.value);
          e.target.classList.remove('is-invalid');
        }
        break;
      case 'title_en':
        result = validateInputField(e.target.value);
        if (result) {
          setToasts([{
            variant: 'error',
            message: `${result.length > 1 ?
                        _('app.thesis.validation.error_invalid_characters') :
                        _('app.thesis.validation.error_invalid_character')} ${result}`,
            show: true}]);
        } else {
          setTitleEn(e.target.value);
          e.target.classList.remove('is-invalid');
        }
        break;
      case 'abstract_orig':
        setAbstractOrig(e.target.value);
        e.target.classList.remove('is-invalid');
        break;
      case 'abstract_en':
        setAbstractEn(e.target.value);
        e.target.classList.remove('is-invalid');
        break;
      case ('confirm_language'):
        if (e.target.checked) setCorrectLanguage(true);
        break;
      case 'has_coauthors':
        setShowCoAuthors(e.target.checked);
        // if unchecked reset coauthors information
        if (!e.target.checked) {
          const newCheckedLegalLabels = checkedLegalLabels.filter((l) => l !== 'group_disclosure');
          setCheckedLegalLabels(newCheckedLegalLabels);
          setCoAuthors([]);
          resetCoauthorsLabel();
        }
        break;
      case 'is_cumulative':
        setShowCuTitles(e.target.checked);
        // if unchecked reset cumulative titles
        if (!e.target.checked) {
          setCuTitles([]);
        }
        break;
      case 'cumulative_titles':
        setNewCuTitle(e.target.value);
        e.target.classList.remove('is-invalid');
        break;
      case 'keywords':
        setNewKeyword(e.target.value);
        e.target.classList.remove('is-invalid');
        break;
      case 'request_blocking':
        thesis.is_blocking = e.target.checked;
        setShowBlockingDetails(thesis.is_blocking);
        if (e.target.checked) {
          thesis.blocking_state.tid = 2;
        } else {
          thesis.blocking_state.tid = 1;
          setBlockingDescription('');
          thesis.blocking_years = null;
        }
        break;
      case 'blocking_years':
        thesis.blocking_years = e.target?.value;
        break;
      case 'blocking_description':
        setBlockingDescription(e.target.value);
        e.target.classList.remove('is-invalid');
        break;
      case 'oenb_loan':
        if (e.target?.checked) {
          const newCheckedLegalLabels = [...checkedLegalLabels, e.target.id];
          setCheckedLegalLabels(newCheckedLegalLabels);
        }
        if (!e.target?.checked) {
          const newCheckedLegalLabels = checkedLegalLabels.filter((l) => l !== e.target.id);
          setCheckedLegalLabels(newCheckedLegalLabels);
        }
        break;
      case ('confirm_check'):
      case ('confirm_submit'):
        if (e.target?.checked) e.target.classList.remove('is-invalid');
        if (!e.target?.checked) e.target.checked = !e.target?.checked;
        break;
      default:
        break;
    }

    // handle required checkboxes
    if (requiredCheckboxes.includes(e.target?.id)) {
      if (e.target?.checked) e.target.classList.remove('is-invalid');
      // no unchecking of checked boxes
      if (!e.target?.checked) e.target.checked = !e.target?.checked;
      // legal labels
      if (e.target?.dataset?.name === 'legal-label') {
        const labelFound = checkedLegalLabels.find((l) => l === e.target.id);
        if (!labelFound) {
          const newCheckedLegalLabels = [...checkedLegalLabels, e.target.id];
          setCheckedLegalLabels(newCheckedLegalLabels);
        }
      }
    };
  };

  /* add a keyword to keywords array */
  const handleAddKeyword = () => {
    if (newKeyword.trim() === '') return;
    const newKeywords = [...keywords, newKeyword];
    if (newKeywords.length > 0) document.querySelector('#keywords').classList.remove('is-invalid');
    setKeywords(newKeywords);
    setNewKeyword('');
  };

  /* remove a keyword to keywords array*/
  const handleDeleteKeyword = (removeIndex) => {
    setToasts([]);
    const newKeywords = keywords.filter((keyword, i) => i != removeIndex);
    if (newKeywords.length === 0) document.querySelector('#keywords').classList.add('is-invalid');
    setKeywords(newKeywords);
  };

  /* add a title to cumulative titles array */
  const handleAddCuTitle = () => {
    if (newCuTitle.trim() === '') return;
    const newCuTitles = [...cuTitles, newCuTitle];
    if (newCuTitles.length > 0) document.querySelector('#cumulative_titles').classList.remove('is-invalid');
    setCuTitles(newCuTitles);
    setNewCuTitle('');
  };

  /* remove a title from cumulative titles array */
  const handleDeleteCuTitle = (removeIndex) => {
    setToasts([]);
    const newCuTitles = cuTitles.filter((keyword, i) => i != removeIndex);
    if (newCuTitles.length === 0) document.querySelector('#cumulative_titles').classList.add('is-invalid');
    setCuTitles(newCuTitles);
  };

  /* modify coauthors and possible coauthors when user selects coauthor option
     - this coauthor is added to the coauthors array
     - change css of select to not be invalid anymore
     - and deleted from the possible coauthors array */
  const handleAddCoAuthor = (e) => {
    setToasts([]);
    if (e.target?.value) {
      // add to coauthors
      const newCoAuthor = possibleCoAuthors.find((coAuthor) =>
        coAuthor.studium === parseInt(e.target.value));
      setCoAuthors([...coAuthors, newCoAuthor]);
      // change css of select to not be invalid anymore
      document.querySelector('#select-coauthors')?.classList?.remove('is-invalid');
      // delete from possible coauthors
      const pCoAuthors = possibleCoAuthors ?
      possibleCoAuthors.filter((coAuthor) =>
        coAuthor.studium != parseInt(e.target.value)) :
      [];
      setPossibleCoAuthors(pCoAuthors);
    }
  };

  /* modify coauthors and possible coauthors when user removes coauthor
     - this coauthor is remove from the coauthors array
     - and added to the possible coauthors array */
  const handleDeleteCoAuthor = (id) => {
    // remove from coauthors
    const newCoAuthors = coAuthors ?
    coAuthors.filter((coAuthor) => coAuthor.studium !== id) :
    [];
    setCoAuthors(newCoAuthors);
    // add to possible coauthors
    const pCoAuthor = coAuthors ?
    coAuthors.find((coAuthor) => coAuthor.studium === id) :
    [];
    setPossibleCoAuthors([...possibleCoAuthors, pCoAuthor]);
  };

  /* handle request to delete attachment
    - find attachment in attachments array
    - delete from attachments array
    - delete from thesis
    - API call to delete attachment */
  const handleDeleteAttachment = (e, removeIndex) => {
    const foundAtt = attachments.find((att, i) => i === removeIndex);
    if (foundAtt.tid) {
      // delete from state attachments
      const newAttachments = attachments.filter((att, i) => i !== removeIndex);
      setAttachments(newAttachments);
      // delete from thesis.attachments
      thesis.attachments = thesis.attachments.filter((att) => att.tid !== foundAtt.tid);
      // API call
      deleteAttachment(foundAtt.tid);
    }
  };

  /* handle request to upload file
     - check file size
     - API call */
  const handleUploadFile = (e) => {
    e.preventDefault();
    const uploadType = e.target.name;
    const file = e.target.files[0];
    setToasts([]);

    // Check thesis file size, must not exceed 25 MB
    if (e.target.id === 'upload_thesis' && file.size > 25 * 1024 * 1024) {
      e.target.value = '';
      setToasts([{
        variant: 'error',
        message: _('app.thesis.validation.error_thesis_size'),
        show: true}]);
      return;
    // Check attachment file size, must not exceed 2 GB
    } else if (e.target.id === 'upload_attachments' && file.size > 2 * (2 ** 30) ||
               e.target.id === 'upload_aid_list' && file.size > 2 * (2 ** 30)) {
      e.target.value = '';
      setToasts([{
        variant: 'error',
        message: _('app.thesis.validation.error_attachment_size'),
        show: true}]);
      return;
    // validate filename
    } else {
      const result = validateFilename(file.name);
      if (result) {
        setToasts([{
          variant: 'error',
          message: `${_('app.thesis.validation.error_filename')} ${result}`,
          show: true}]);
        document.querySelector(`#${e.target.id}`).value = '';
        return;
      }
      uploadFile(uploadType, file);
    }
  };

  return (
    <>
      <div className='toast-container'>
        <UI.ToastMessage toasts={toasts} handleClose={handleClose} />
      </div>
      {xhrLoad && <UI.AjaxLoading />}
      {!xhrLoad && <>
        {/* submit form */}
        <Row className="mt-4">
          <Col xxl={9}>
            <Card>
              <Card.Header as='h5'>{_('app.thesis.thesis_form')}</Card.Header>
              <Card.Body>
                {thesis.reviewers?.length === 0 && thesis.type?.tid === 3 &&
                  <Row>
                    <Col className='mb-2'>
                      <UI.InfoBox>
                        <UI.InfoText>
                          {_(`app.thesis.phd_data_incomplete`)}
                        </UI.InfoText>
                      </UI.InfoBox>
                    </Col>
                  </Row>
                }
                <Row>
                  <Col md={3}>
                    <p>{_('app.thesis.student')}</p>
                  </Col>
                  <Col>
                    <Badge bg='mainmedium me-2' className='mnr-badge'>
                      {thesis.student?.matricle ? thesis.student.matricle.toString().padStart(8, '0') : ''}
                    </Badge>
                    {thesis.student?.firstname ? thesis.student.firstname + ' ' : ''}
                    {thesis.student?.surname || ''}
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <p>{_('app.thesis.type')}</p>
                  </Col>
                  <Col>
                    {_(`app.thesis.types.${thesis.type.tid}.long`)}
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <p>{_('app.thesis.reviewers')}</p>
                  </Col>
                  <Col>
                    {thesis.reviewers?.length > 0 ?
                      thesis.reviewers.map((reviewer, i) => {
                        return <div key={'reviewer-' +i}>{reviewer.fullname} ({reviewer.role})</div>;
                      }) : _('app.thesis.not_available')
                    }
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <p>{_('app.thesis.study')}</p>
                  </Col>
                  <Col>
                    {language === 'de' ? thesis.degree_program : thesis.degree_program_en}
                  </Col>
                </Row>
                <Row className='datebox'>
                  <Col>
                    <Form.Group>
                      <Form.Label htmlFor="title_orig">
                        {_('app.thesis.title_orig')}
                        <UI.InfoText>{_('app.thesis.title_equals')}</UI.InfoText>
                      </Form.Label>
                      <input
                        className="ps-2 form-control shadow-sm"
                        type="text"
                        id="title_orig"
                        name="title_orig"
                        maxLength={2000}
                        onChange={handleChange}
                        value={titleOrig}
                        placeholder={_('app.thesis.title_orig')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {thesis.language !== 'en' &&
                <Row className='datebox'>
                  <Col>
                    <Form.Group>
                      <Form.Label htmlFor="title_en">
                        {_('app.thesis.title_en')}
                        <UI.InfoText>{_('app.thesis.title_equals')}</UI.InfoText>
                      </Form.Label>
                      <input
                        className="ps-2 form-control shadow-sm"
                        type="text"
                        id="title_en"
                        name="title_en"
                        maxLength={2000}
                        onChange={handleChange}
                        value={titleEn}
                        placeholder={_('app.thesis.title_en')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                }
                {thesis.type?.tid === 3 && <>
                  <Row className='datebox'>
                    <Col>
                      <Form.Group>
                        <Form.Label htmlFor="is_cumulative">
                          {_('app.thesis.cumulative_work')}
                        </Form.Label>
                        <Form.Check
                          type="checkbox"
                          checked={showCuTitles}
                          id="is_cumulative"
                          name="is_cumulative"
                          onChange={handleChange}
                          label={_('app.thesis.is_cumulative')}
                        />
                      </Form.Group>
                      {showCuTitles && <>
                        <Row className='mt-0 mb-0'>
                          <Col className='p-0'>
                            <Form.Label htmlFor="cumulative_titles">
                              <UI.InfoText>{_('app.thesis.titles_cu_info')}</UI.InfoText>
                            </Form.Label>
                            <Stack direction="horizontal" gap={2}>
                              <input
                                className="ps-2 form-control shadow-sm"
                                type="text"
                                id="cumulative_titles"
                                name="cumulative_titles"
                                placeholder={_('app.thesis.cumulative_work_placeholder')}
                                value={newCuTitle}
                                maxLength={2000}
                                onChange={handleChange}
                                onKeyDown={(e) => {
                                  if (e?.target?.value?.trim() !== '' && e.key === 'Enter') {
                                    handleAddCuTitle();
                                  }
                                }}
                              />
                              <Button
                                size='lg'
                                className='p-2 add-button'
                                onClick={handleAddCuTitle}
                              >+</Button>
                            </Stack>
                          </Col>
                        </Row>
                        {cuTitles && cuTitles.length > 0 && <>
                          <Row className='m-0'>
                            <Col className='ps-0'>
                              <ul>
                                {cuTitles.map((cuTitle, i) => {
                                  return <li
                                    key={'cuTitle-' +i}
                                    className='d-flex mt-2'>
                                    <Button
                                      className="delete-button"
                                      onClick={(e) =>
                                        handleDeleteCuTitle(i, e)}
                                      size="lg"
                                      variant="danger"
                                    >x</Button>
                                    <span className='px-2 my-auto mx-0'>{cuTitle}</span>
                                  </li>;
                                })
                                }
                              </ul>
                            </Col>
                          </Row>
                        </>
                        }
                      </>
                      }
                    </Col>
                  </Row>

                  <Row className='datebox'>
                    <Col>
                      <Form.Group>
                        <Form.Label htmlFor="abstract_orig">
                          {_('app.thesis.abstract_orig')}
                        </Form.Label>
                        <textarea
                          className="ps-2 form-control shadow-sm"
                          id="abstract_orig"
                          name="abstract_orig"
                          onChange={handleChange}
                          value={abstractOrig}
                          placeholder={_('app.thesis.abstract_orig')}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {thesis.language !== 'en' &&
                    <Row className='datebox'>
                      <Col>
                        <Form.Group>
                          <Form.Label htmlFor="abstract_en">
                            {_('app.thesis.abstract_en')}
                          </Form.Label>
                          <textarea
                            className="ps-2 form-control shadow-sm"
                            id="abstract_en"
                            name="abstract_en"
                            onChange={handleChange}
                            value={abstractEn}
                            placeholder={_('app.thesis.abstract_en')}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  }
                </>}
                <Row className='datebox'>
                  <Col>
                    <Form.Group >
                      <Form.Label htmlFor="confirm_language">
                        {_('app.thesis.language')}
                        <UI.InfoText>{_(`app.thesis.wrong_language.${thesis.type.tid}`)}</UI.InfoText>
                      </Form.Label>
                      <Form.Check
                        type="checkbox"
                        id="confirm_language"
                        name="confirm_language"
                        label={`${_('app.thesis.confirm_language')} "${language && thesis.language_names && thesis.language_names[language] ?
                          thesis.language_names[language] :
                          thesis.language || _('app.thesis.not_available')}".`}
                        checked={correctLanguage}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {thesis.type?.tid !== 3 && <>
                  <Row className='datebox'>
                    <Col>
                      <Form.Group>
                        <Form.Label htmlFor="has_coauthors">
                          {_('app.thesis.collaborative_work')}
                          {showCoAuthors && <>
                            <UI.InfoText>{_('app.thesis.no_coauthors')}</UI.InfoText>
                            <UI.InfoText>{_('app.thesis.coauthor_auth')}</UI.InfoText>
                          </>
                          }
                        </Form.Label>
                        <Form.Check
                          type="checkbox"
                          checked={showCoAuthors}
                          id="has_coauthors"
                          name="has_coauthors"
                          onChange={handleChange}
                          label={_('app.thesis.has_coauthors')}
                        />
                      </Form.Group>
                      {showCoAuthors && <>
                        <Row className='mt-3 mb-0'>
                          <Col md={3} className='p-0 pe-2 mb-2'>
                            <Form.Select aria-label="Select coauthors"
                              className={`ps-2 smallselect`}
                              id="select-coauthors"
                              name="select-coauthors"
                              multiple={false}
                              defaultValue={0}
                              onChange={handleAddCoAuthor}
                            >
                              <option key="0" value="0">
                                {_('app.thesis.choose_coauthors')}
                              </option>
                              {possibleCoAuthors.length > 0 && possibleCoAuthors.
                                  sort((a, b) => {
                                    if (a.student_id < b.student_id) {
                                      return -1;
                                    } else {
                                      return 1;
                                    }
                                  })
                                  .map((coAuthor) => (
                                    <option
                                      key={coAuthor.studium}
                                      value={coAuthor.studium}
                                    >
                                      {coAuthor.student_id?.toString()
                                          .padStart(8, '0')}
                                    </option>
                                  ))}
                            </Form.Select>
                          </Col>
                          <Col className='ps-0'>
                            {coAuthors.length > 0 && coAuthors.map((coauthor) => {
                              return <Badge
                                bg="mainmedium me-2 p-0 mb-2"
                                key={'coauthor-'+ coauthor.studium}>
                                <span className='px-2'>
                                  {coauthor.student_id?.toString().padStart(8, '0')}
                                </span>
                                <Button
                                  className="delete-button"
                                  onClick={(e) =>
                                    handleDeleteCoAuthor(coauthor.studium, e)}
                                  size="lg"
                                  variant="danger">x</Button>
                              </Badge>;
                            })
                            }
                          </Col>
                        </Row>
                      </>
                      }
                    </Col>
                  </Row>
                </>}
                <Row className='datebox'>
                  <Col>
                    <Form.Label htmlFor="keywords">
                      {_('app.thesis.keywords')}
                      <UI.InfoText>{_('app.thesis.define_keywords')}</UI.InfoText>
                    </Form.Label>
                    <Row className='mt-0 mb-0'>
                      <Col md={4} className='ps-0 pe-2 mb-2'>
                        <Stack direction="horizontal" gap={2}>
                          <input
                            className="ps-2 form-control shadow-sm"
                            type="text"
                            id="keywords"
                            name="keywords"
                            placeholder={_('app.thesis.keywords_placeholder')}
                            value={newKeyword}
                            onChange={handleChange}
                            onKeyDown={(e) => {
                              if (e?.target?.value?.trim() !== '' && e.key === 'Enter') {
                                handleAddKeyword();
                              }
                            }}
                          />
                          <Button
                            size='lg'
                            className='p-2 add-button'
                            onClick={handleAddKeyword}
                          >+</Button>
                        </Stack>
                      </Col>
                      <Col className='ps-0'>
                        {keywords?.map((keyword, i) => {
                          return <Badge bg="mainmedium me-2 p-0 mb-2" key={'keyword-' +i}>
                            <span className='px-2'>{keyword}</span>
                            <Button
                              className="delete-button"
                              onClick={(e) =>
                                handleDeleteKeyword(i, e)}
                              size="lg"
                              variant="danger"
                            >x</Button>
                          </Badge>;
                        })
                        }
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className='datebox'>
                  <p>{_('app.thesis.upload_documents')}</p>
                  <Form.Label>
                    <UI.InfoText>{_('app.thesis.info_file_name')}</UI.InfoText>
                    {thesis.type?.tid !== 1 && <UI.InfoText>{_('app.thesis.info_file_publication')}</UI.InfoText>}
                  </Form.Label>
                  <Col className='mt-2' md={4}>
                    <Form.Group>
                      <Form.Label htmlFor="upload_thesis">
                        {_('app.thesis.upload_thesis')}
                      </Form.Label>
                      <input
                        className="ps-2 shadow-sm form-control"
                        type="file"
                        accept="application/pdf"
                        id="upload_thesis"
                        name="upload_thesis"
                        {... (draftPdf && {disabled: 'disabled'})}
                        onChange={handleUploadFile}
                      />
                    </Form.Group>
                    {draftPdf &&
                      <ul>
                        <li
                          key='draft_pdf'
                          className='d-flex mt-2'>
                          <Button
                            className="delete-button"
                            onClick={deleteDraftPdf}
                            size="lg"
                            variant="danger"
                          >x</Button>
                          <span className='px-2 my-auto mx-0 text-break'>{draftPdf}</span>
                        </li>
                      </ul>
                    }
                    {draftPdf &&
                      <div className='mt-2'>
                        <UI.InfoText>{_('app.thesis.info_file_upload')}</UI.InfoText>
                      </div>
                    }
                  </Col>
                  <Col className='mt-2' md={4}>
                    <Form.Group>
                      <Form.Label htmlFor="upload_attachments">
                        {_('app.thesis.upload_attachments')}
                      </Form.Label>
                      <input
                        className="ps-2 shadow-sm form-control"
                        type="file"
                        id="upload_attachments"
                        name="upload_attachments"
                        onChange={handleUploadFile}
                      />
                    </Form.Group>
                    {attachments?.length > 0 &&
                      <ul>
                        {attachments.map((att, i) => {
                          if (att.to_publish) {
                            return <li
                              key={'att-' + i}
                              className='d-flex mt-2'>
                              <Button
                                className="delete-button"
                                onClick={(e) => handleDeleteAttachment(e, i)}
                                size="lg"
                                variant="danger"
                              >x</Button>
                              <span className='px-2 my-auto mx-0 text-break'>{att.filename_orig}</span>
                            </li>;
                          }
                        })
                        }
                      </ul>
                    }
                  </Col>
                  <Col className='mt-2' md={4}>
                    <Form.Group>
                      <Form.Label htmlFor="upload_aid_list">
                        {_('app.thesis.upload_aid_list')}
                      </Form.Label>
                      <input
                        className="ps-2 shadow-sm form-control"
                        type="file"
                        accept="application/pdf"
                        id="upload_aid_list"
                        name="upload_aid_list"
                        {... (attachments.filter((att) => !att.to_publish).length > 0 && {disabled: 'disabled'})}
                        onChange={handleUploadFile}
                      />
                    </Form.Group>
                    {attachments?.length > 0 &&
                      <ul>
                        {attachments.map((att, i) => {
                          if (!att.to_publish) {
                            return <li
                              key={'att-' + i}
                              className='d-flex mt-2'>
                              <Button
                                className="delete-button"
                                onClick={(e) => handleDeleteAttachment(e, i)}
                                size="lg"
                                variant="danger"
                              >x</Button>
                              <span className='px-2 my-auto mx-0 text-break'>{att.filename_orig}</span>
                            </li>;
                          }
                        })
                        }
                      </ul>
                    }
                    {attachments.filter((att) => !att.to_publish).length > 0 &&
                      <div className='mt-2'>
                        <UI.InfoText>{_('app.thesis.info_file_upload')}</UI.InfoText>
                      </div>
                    }
                  </Col>
                </Row>
                {thesis.type?.tid !== 1 &&
                  <Row className='datebox'>
                    <Col>
                      <Form.Group>
                        <Form.Label htmlFor="request_blocking">
                          {_('app.thesis.blocking')}
                        </Form.Label>
                        <Form.Check
                          type="checkbox"
                          id="request_blocking"
                          name="request_blocking"
                          defaultChecked={thesis.is_blocking}
                          onChange={handleChange}
                          label={thesis.type.tid === 3 ? _('app.thesis.request_blocking_phd') :
                                  thesis.type.tid === 2 ? _('app.thesis.request_blocking_ma') : _('app.thesis.not_available') }
                        />
                      </Form.Group>
                    </Col>

                    {showBlockingDetails && <>
                      {showCoAuthors &&
                        <Row>
                          <Col className="p-0">
                            <UI.InfoText>{_('app.thesis.request_block_coauthors_auth')}</UI.InfoText>
                          </Col>
                        </Row>}
                      <Row className='p-0'>
                        <Col md={3}>
                          <Form.Group>
                            <Form.Label htmlFor="blocking_years">
                              {_('app.thesis.request_block_years')}:
                            </Form.Label>
                            <Form.Select aria-label="Select blocking years"
                              className={`ps-2 smallselect`}
                              id="blocking_years"
                              name="blocking_years"
                              defaultValue={thesis.blocking_years || 0}
                              onChange={handleChange}
                            >
                              <option key="0" value="0" disabled="disabled">
                                {_('app.thesis.request_block_select')}
                              </option>
                              {[1, 2, 3, 4, 5].map((year) => {
                                return <option key={year}
                                  value={year}>{year}
                                  {language==='de' ?
                              (year == 1 ? ' Jahr' : ' Jahre') :
                              (year == 1 ? ' year' : ' years')}
                                </option>;
                              })
                              }
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label htmlFor="blocking_description">
                              {_('app.thesis.reason')}
                            </Form.Label>
                            <textarea
                              className="ps-3 form-control shadow-sm"
                              id="blocking_description"
                              name="blocking_description"
                              maxLength={1000}
                              onChange={handleChange}
                              value={blockingDescription}
                              placeholder={_('app.thesis.reason_blocking_placeholder')}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                    }
                  </Row>
                }
                <Row className='datebox'>
                  <Col>
                    <Form.Group>
                      <Form.Label htmlFor="own_work">
                        {_('app.thesis.legal_confirmation')}
                      </Form.Label>
                      <div>{_('app.thesis.confirm_legal')}</div>
                      {legalLabels.length > 0 && <>
                        {legalLabels.map((label) => {
                          return (label.language === language &&
                            (label.label !== 'group_disclosure' || showCoAuthors)) ?
                        <Row key={'label-' + label.tid}>
                          <Form.Check
                            type="checkbox"
                            id={label.label}
                            data-name="legal-label"
                            label={label.text}
                            defaultChecked={checkedLegalLabels.findIndex((l) => l === label.label) !== -1}
                            onChange={handleChange}
                          />
                        </Row> : '';
                        })
                        }
                      </>
                      }
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Button
                    id="save-thesis"
                    type="button"
                    className="thesis-button me-2"
                    variant="secondary"
                    onClick={handleSave}
                  >
                    {_('app.thesis.save')}
                  </Button>
                  <Button
                    id="submit-thesis"
                    type="button"
                    className="thesis-button"
                    variant="primary"
                    onClick={handleSubmitValidate}
                  >
                    {_('app.thesis.submit')}
                  </Button>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* thesis history */}
        <TableThesisHistory drafts={thesis.drafts}/>
      </>
      }
      {/* confirm modal for submitting thesis  */}
      <UI.ConfirmModal
        show={showConfirm}
        handler={handleSubmitConfirm}
        handleClose={() => {
          setShowConfirm(false);
        }}
        message={
          <>
            {thesis.type.tid === 3 &&
              <div className="oenb-info-box mb-3">
                <UI.InfoBox titlesmall={_('app.thesis.oenb_info_title')}>
                  <UI.InfoText>
                    {_('app.thesis.oenb_info_text')}
                  </UI.InfoText>
                </UI.InfoBox>
              </div>
            }
            <Form.Group
              className="ms-3">
              <Form.Check
                type="checkbox"
                id="confirm_submit"
                name="confirm_submit"
                className='d-block ps-2'
                label={_('app.thesis.confirm_submit')}
                defaultChecked={false}
                onChange={handleChange}
              />
              <Form.Check
                type="checkbox"
                id="confirm_check"
                name="confirm_check"
                className='d-block ps-2 bg-red'
                label={_('app.thesis.confirm_check')}
                defaultChecked={false}
                onChange={handleChange}
              />
            </Form.Group>
          </>
        }
        buttontext={_('app.thesis.confirm_ok')}
      />
    </>
  );
};

export default FormThesis;
