import {useTranslation} from '@util/bachhelpers.js';
import './Calendar.css';
import CalendarWeek from './CalendarWeek.jsx';
import CalendarMonth from './CalendarMonth.jsx';
import PropTypes from 'prop-types';

function Calendar( props ) {
  const {_} = useTranslation();
  let weekdays = [];
  let weekdaysLong = [];

  weekdays = [
    _('schedule.mon'),
    _('schedule.tue'),
    _('schedule.wed'),
    _('schedule.thu'),
    _('schedule.fri'),
    _('schedule.sat'),
    _('schedule.sun'),
    _('schedule.today')];

  weekdaysLong = [
    _('schedule.monday'),
    _('schedule.tuesday'),
    _('schedule.wednesday'),
    _('schedule.thursday'),
    _('schedule.friday'),
    _('schedule.saturday'),
    _('schedule.sunday')];

  return (
    <>
      {props.variant === 'week' && (<CalendarWeek
        weekdays={weekdays}
        weekdaysLong={weekdaysLong}
      />)}
      {props.variant === 'month' && (<CalendarMonth
        weekdays={weekdays}
        weekdaysLong={weekdaysLong}
      />)}
      {props.variant === 'monthLVPlaner' && (<CalendarMonth
        weekdays={weekdays}
        weekdaysLong={weekdaysLong}
        lvPlFavDB={props.lvPlFavDB}
      />)}
    </>
  );
}
Calendar.propTypes={variant: PropTypes.string, lvPlFavDB: PropTypes.array};
export default Calendar;
