import {useState, useMemo, useRef} from 'react';
import {Button, Form} from 'react-bootstrap';
import {X} from 'react-bootstrap-icons';
import {useTranslation} from '@util/bachhelpers.js';
import PropTypes from 'prop-types';
import DataTable from 'react-data-table-component';
import './BachDataTable.css';

function BachDataTable(props) {
  const ref = useRef();
  const {_} = useTranslation();
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText('');
    }
  };

  const FilterComponent = ({onFilter, filterText}) => (
    <>
      <div>
        <Form.Control
          ref={ref}
          id="filter"
          type="text"
          aria-label="Search Input"
          value={filterText}
          onChange={(e) => onFilter(e.target.value)}
          autoFocus
          className="ps-3 mt-2 right-0"
        />
      </div>
      <Button id="clear-filter" type="button" onClick={handleClear}>
        <X color="white" fontSize={20} />
      </Button>
    </>
  );

  const filteredItems = useMemo(() => {
    if (!props.filterCols) return props.data;

    return props.data.filter((item) =>
      props.filterCols.some((col) => {
        if (item[col]) {
          if (Array.isArray(item[col])) {
            const flattened = item[col].join('').toLowerCase();
            return flattened.includes(filterText.toLowerCase());
          } else {
            return item[col].toLowerCase().includes(filterText.toLowerCase());
          }
        }
        return false;
      }),
    );
  }, [filterText, props.data, props.filterCols]);

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <>
        {!props.hideFilter && (
          <FilterComponent
            onFilter={setFilterText}
            filterText={filterText}
          />
        )}
        {!props.hideFilter && !filterText && (
          <div
            id="filter-placeholder"
            onClick={() => ref.current && ref.current.focus()}
          >
            {props.placeholder || _('general.datatable_filter')}
          </div>
        )}
      </>
    );
  }, [filterText, props.hideFilter]);

  return (
    <div className="table-container">
      <div className="d-flex justify-content-between align-items-center">
        {!props.hideCount && (
          <div className="float-start result">
            {props.data.length} {_('app.course_search_dt.results_found')}
          </div>
        )}
        {props.hideCount && <div className="pb-3" />}
        <div id="filter-container" className="float-end mb-4">
          {!props.hideFilter && subHeaderComponentMemo}
        </div>
      </div>

      <div className="table-responsive">
        <DataTable
          columns={props.columns}
          data={filteredItems}
          noDataComponent={props.noDataComponent}
          pagination
          paginationResetDefaultPage={resetPaginationToggle}
          paginationComponentOptions={{
            rowsPerPageText: _('general.rows_per_page'),
            rangeSeparatorText: _('general.range_sep_text'),
          }}
          defaultSortFieldId={props.defaultSortFieldId}
          expandableRows={props.useExpandableRows}
          expandableRowsComponent={props.expandableRowsComponent}
        />
      </div>
    </div>
  );
}

BachDataTable.propTypes = {
  inputPlaceholder: PropTypes.string,
  noDataComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  columns: PropTypes.array,
  hideFilter: PropTypes.bool,
  hideCount: PropTypes.bool,
  filterCols: PropTypes.array,
  data: PropTypes.array,
  filterText: PropTypes.string,
  onFilter: PropTypes.object,
  onClear: PropTypes.object,
  placeholder: PropTypes.string,
  defaultSortFieldId: PropTypes.string,
  useExpandableRows: PropTypes.bool,
  expandableRowsComponent: PropTypes.func,
};

export default BachDataTable;
