/* eslint-disable max-len */
/* eslint-disable camelcase */
import {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Badge, Button, Card, Col, Form, Nav, Row, Tab} from 'react-bootstrap';
import {Search} from 'react-bootstrap-icons';

import UI from '@ui/UI';
import SemesterList from '@ui/SemesterList';
import {BA, useTranslation} from '@util/bachhelpers.js';
import {LANGUAGES, URL_TYPES} from '@util/consts';
import MyFavorites from '@components/Partials/MyFavorites';
import Calendar from '@components/Calendar/Calendar';

import './LVPlaner.css';

function LVPlaner() {
  const {_} = useTranslation();
  const dispatch = useDispatch();

  const weekdays = [
    _('schedule.mon'),
    _('schedule.tue'),
    _('schedule.wed'),
    _('schedule.thu'),
    _('schedule.fri'),
    _('schedule.sat'),
    _('schedule.sun'),
    _('schedule.today'),
  ];

  const language = useSelector((state) => state.language);
  const userInfo = useSelector((state) => state.userInfo);
  const currentSemester = useSelector((state) => state.currentSemester);
  const studies = useSelector((state) => state.studies);
  const currentStudy = useSelector((state) => state.currentStudy);

  const [xhrload, setXhrload] = useState(false);

  const [tabSearchAndSearchSug, setTabSearchAndSearchSug] = useState('tabSearch');
  const [isTabSearchAndSearchSugCollapsed, setIsTabSearchAndSearchSugCollapsed] = useState(false);

  // courseSearchResult (response data) from function loadCourses.
  const courseSearchResult = useSelector((state) => state.courseSearchResult);

  const [searchText, setSearchText] = useState(sessionStorage.getItem('courseSearchText') || '');
  const [searchResultLoad, setSearchResultLoad] = useState(false);

  const [isFilterCollapsed, setIsFilterCollapsed] = useState(false);

  const [isCollisionFilteredOut, setIsCollisionFilteredOut] = useState(false);

  const [courseTypes, setCourseTypes] = useState([]);
  const [selectedCourseType, setSelectedCourseType] = useState(_('app.lvplaner.filter_all'));

  const [courseLanguages, setCourseLanguages] = useState([]);
  const [selectedCourseLanguage, setSelectedCourseLanguage] = useState(_('app.lvplaner.filter_all'));

  const [courseModes, setCourseModes] = useState([]);
  const [selectedCourseMode, setSelectedCourseMode] = useState(_('app.lvplaner.filter_all'));

  const [isMonChecked, setIsMonChecked] = useState(true);
  const [isTueChecked, setIsTueChecked] = useState(true);
  const [isWedChecked, setIsWedChecked] = useState(true);
  const [isThuChecked, setIsThuChecked] = useState(true);
  const [isFriChecked, setIsFriChecked] = useState(true);
  const [isSatChecked, setIsSatChecked] = useState(true);

  const [isMorningChecked, setIsMorningChecked] = useState(true);
  const [isAfternoonChecked, setIsAfternoonChecked] = useState(true);
  const [isEveningChecked, setIsEveningChecked] = useState(true);

  // lvPlSearchResultsDetails (response data) from function getCourseDetails.
  const [lvPlSearchResultsDetails, setLvPlSearchResultsDetails] = useState([]);
  // lvPlSearchResultStored = lvPlSearchResultsDetails from function getCourseDetails.
  const lvPlSearchResultStored = useSelector((state) => state.lvPlSearchResultStored);

  const [lvPlFavDB, setLvPlFavDB] = useState([]);

  const [collisionLvNr, setCollisionLvNr] = useState({});

  const [infoCurrentStudyMissing, setInfoCurrentStudyMissing] = useState(false);

  const [errorGetSavedLvPlFavsDB, setErrorGetSavedLvPlFavsDB] = useState(false);
  const [errorGetCourseDetails, setErrorGetCourseDetails] = useState(false);
  const [errorSaveLvPlFavDB, setErrorSaveLvPlFavDB] = useState(false);
  const [errorDeleteLvPlFavDB, setErrorDeleteLvPlFavDB] = useState(false);

  const [tabPrioAndFavAndSearchRes, setTabPrioAndFavAndSearchRes] = useState('tabFav');
  const [isTabPrioAndFavAndSearchResCollapsed, setIsTabPrioAndFavAndSearchResCollapsed] = useState(false);

  const [tabSemMonth, setTabSemMonth] = useState('tabSem');
  const [isTabSemMonthCollapsed, setIsTabSemMonthCollapsed] = useState(false);

  const columnsLvPlFavsDB = [
    {
      id: 'verid',
      selector: (row) => (
        <div>
          {row.lv_nr}
        </div>
      ),
      name: _('app.lvplaner.lv_nr'),
      wrap: true,
      minWidth: '60px',
      maxWidth: '6rem',
    },
    {
      id: 'veridCollision',
      selector: (row) => {
        const collisionResults = [];

        row.events.forEach((event) => {
          const collisionFound = checkCollision(row.lv_nr, event.beginn, event.ende);

          if (collisionFound) {
            const results = Object.keys(collisionLvNr).filter((key) => {
              return collisionLvNr[key].includes(row.lv_nr);
            });

            results.forEach((result) => {
              if (!collisionResults.includes(result)) {
                collisionResults.push(result);
              }
            });
          }
        });

        if (collisionResults.length > 0) {
          return (
            <div className='text-danger'>
              {collisionResults.join(', ')}
            </div>
          );
        }

        return null;
      },
      name: _('app.lvplaner.lv_overlap'),
      wrap: true,
      minWidth: '80px',
      maxWidth: '8.5rem',
    },
    {
      id: 'titel',
      selector: (row) => (
        <div>
          <UI.BachLink
            href={BA.buildUIRouteUrl(URL_TYPES.COURSE_DETAILS, {id: row.lv_nr, semester: row.semester})}>
            {language === LANGUAGES.EN ? row.titel_en : row.titel}
          </UI.BachLink>
        </div>
      ),
      name: _('app.lvplaner.lv_title'),
      wrap: true,
      minWidth: '160px',
    },
    {
      id: 'teacher',
      selector: (row) => row.teachers.map((teacher, teacherIndex) => (
        <div key={teacherIndex} className='mb-0'>
          <UI.BachLink
            href={BA.buildUIRouteUrl(URL_TYPES.COURSE_INSTRUCTORS, {id: teacher.tid, semester: row.semester})}>
            {teacher.zuname} {teacher.vorname}
          </UI.BachLink>
        </div>
      )),
      name: _('app.lvplaner.lv_instructor'),
      wrap: true,
      minWidth: '160px',
    },
    {
      id: 'delete',
      cell: (row) => (
        <button
          className="btn btn_close_bach"
          onClick={() => deleteLvPlFavDB(row)}
        >
        </button>
      ),
      name: _('app.lvplaner.btn_action'),
      wrap: true,
      minWidth: '65px',
      maxWidth: '6.5rem',
    },
  ];

  const columnsLvPlSearchResult = [
    {
      id: 'verid',
      selector: (row) => (
        <div>
          {row.courses.course_info.verid}
        </div>
      ),
      name: _('app.lvplaner.lv_nr'),
      wrap: true,
      minWidth: '60px',
      maxWidth: '6rem',
    },
    {
      id: 'veridCollision',
      selector: (row) => {
        const collisionResults = [];

        row.courses.events.forEach((event) => {
          const collisionFound = checkCollision(row.courses.course_info.verid, event.start_time, event.end_time);

          if (collisionFound) {
            const results = Object.keys(collisionLvNr).filter((key) => {
              return collisionLvNr[key].includes(row.courses.course_info.verid);
            });

            results.forEach((result) => {
              if (!collisionResults.includes(result)) {
                collisionResults.push(result);
              }
            });
          }
        });

        if (collisionResults.length > 0) {
          return (
            <div className='text-danger'>
              {collisionResults.join(', ')}
            </div>
          );
        }

        return null;
      },
      name: _('app.lvplaner.lv_overlap'),
      wrap: true,
      minWidth: '80px',
      maxWidth: '8.5rem',
    },
    {
      id: 'titel',
      selector: (row) => (
        <div>
          <UI.BachLink
            href={BA.buildUIRouteUrl(URL_TYPES.COURSE_DETAILS, {id: row.courses.course_info.verid, semester: currentSemester.id})}>
            {language === LANGUAGES.EN ? row.courses.course_info.titel_en : row.courses.course_info.titel_de}
          </UI.BachLink>
        </div>
      ),
      name: _('app.lvplaner.lv_title'),
      wrap: true,
      minWidth: '160px',
    },
    {
      id: 'teacher',
      selector: (row) => row.courses.teachers.map((teacher, teacherIndex) => (
        <div key={teacherIndex} className='mb-0'>
          <UI.BachLink
            href={BA.buildUIRouteUrl(URL_TYPES.COURSE_INSTRUCTORS, {id: teacher.tid, semester: currentSemester.id})}>
            {teacher.zuname} {teacher.vorname}
          </UI.BachLink>
        </div>
      )),
      name: _('app.lvplaner.lv_instructor'),
      wrap: true,
      minWidth: '160px',
    },
    {
      id: 'save',
      cell: (row) => (
        <div className="action_column">
          <a
            className="btn bi-save"
            onClick={() => saveLvPlFavDB(row.courses.course_info)}
          >
          </a>
        </div>
      ),
      name: _('app.lvplaner.btn_action'),
      wrap: true,
      minWidth: '65px',
      maxWidth: '6.5rem',
    },
  ];

  const columnsLvPlFavsDBSemCal = [
    {
      id: 'date',
      selector: (row) => (
        <div>
          {row.startDateFormatted}
        </div>
      ),
      name: _('app.lvplaner.calendar_semester_date'),
      wrap: true,
      minWidth: '80px',
      maxWidth: '8rem',
    },
    {
      id: 'time',
      selector: (row) => row.courses.map((course, courseIndex) => (
        <div key={courseIndex} className='mb-2'>
          <div className={course.hasCollision ? 'text-danger' : ''}>{course.startTime} - {course.endTime}</div>
        </div>
      )),
      name: _('app.lvplaner.calendar_semester_time'),
      wrap: true,
      minWidth: '90px',
      maxWidth: '9rem',
    },
    {
      id: 'verid',
      selector: (row) => row.courses.map((course, courseIndex) => (
        <div key={courseIndex} className='mb-2'>
          <div className={course.hasCollision ? 'text-danger' : ''}>{course.lv_nr}</div>
        </div>
      )),
      name: _('app.lvplaner.calendar_semester_verid'),
      wrap: true,
      minWidth: '90px',
      maxWidth: '9rem',
    },
    {
      id: 'titel',
      selector: (row) => row.courses.map((course, courseIndex) => (
        <div key={courseIndex} className='mb-2'>
          <div className=''>
            <UI.BachLink
              href={BA.buildUIRouteUrl(URL_TYPES.COURSE_DETAILS, {id: course.lv_nr, semester: course.semester})}
              className={course.hasCollision ? 'text-danger' : ''}>
              {language === LANGUAGES.EN ? course.titel_en : course.titel}
            </UI.BachLink>
          </div>
        </div>
      )),
      name: _('app.lvplaner.calendar_semester_title'),
      wrap: true,
      minWidth: '160px',
    },
    {
      id: 'room',
      selector: (row) => row.courses.map((course, courseIndex) => (
        <div key={courseIndex} className='mb-2'>
          <div className={course.hasCollision ? 'text-danger' : ''}>
            {course.raum.length > 15 ? course.raum.slice(0, 15) + '...' : course.raum}
          </div>
        </div>
      )),
      name: _('app.lvplaner.calendar_semester_room'),
      wrap: true,
      minWidth: '90px',
      maxWidth: '10rem',
    },
  ];

  const filteredStudiesForLvPl = studies.filter(
      (study) => study.admissionCode !== 'X' && study.admissionCode !== 'Z',
  );

  const setStudy = () => {
    if (filteredStudiesForLvPl.length === 1) {
      dispatch({
        type: 'set_current_study',
        currentStudy: {
          id: filteredStudiesForLvPl[0].id,
          studyCode: filteredStudiesForLvPl[0].studyCode,
          nameEn: filteredStudiesForLvPl[0].nameEn,
          nameDe: filteredStudiesForLvPl[0].nameDe,
          admissionCode: filteredStudiesForLvPl[0].admissionCode,
        },
      });
    }
  };

  useEffect(() => {
    setStudy();
  }, []);

  useEffect(() => {
    setStudy();
  }, [studies]);

  const getSavedLvPlFavsDB = async () => {
    if (currentStudy.id === -1) {
      setLvPlFavDB([]);
      return;
    };

    if (!xhrload) {
      setXhrload(true);

      setErrorGetSavedLvPlFavsDB(false);
      setInfoCurrentStudyMissing(false);
      setCollisionLvNr([]);

      try {
        const response = await BA.ajax({
          url: BA.buildApiUrl(URL_TYPES.GET_SAVED_LV_PL_FAVS, {study: currentStudy.id}),
          bpoapi: true,
        });

        if (response.data) {
          const lvPlFavDBResponse = response.data.lv_pl_fav_db;
          const lvPlFavDBCopy = [];

          lvPlFavDBResponse.forEach((course) => {
            let courseExists = lvPlFavDBCopy.find((c) => c.lv_tid === course.lv_tid);

            // If the course does not exist, create it
            if (!courseExists) {
              courseExists = {
                lv_tid: course.lv_tid,
                lv_nr: course.lv_nr.toString().padStart(4, '0'),
                titel: course.titel,
                titel_en: course.titel_en,
                lv_typ: course.lv_typ,
                lv_sprache: course.lv_sprache,
                lv_sprache_en: course.lv_sprache_en,
                lv_didaktik: course.lv_didaktik,
                anmeldung_von: course.anmeldung_von,
                anmeldung_bis: course.anmeldung_bis,
                semester: course.semester,
                teachers: [],
                events: [],
              };
              lvPlFavDBCopy.push(courseExists);
            }

            // Add teacher if not already present
            if (!courseExists.teachers.find((t) => t.zuname === course.zuname && t.vorname === course.vorname)) {
              courseExists.teachers.push({
                tid: course.tid,
                akad_grad_prae: course.akad_grad_prae,
                zuname: course.zuname,
                vorname: course.vorname,
                akad_grad_post: course.akad_grad_post,
              });
            }

            // Add event if not already present
            if (!courseExists.events.find((e) => e.beginn === course.beginn && e.ende === course.ende && e.raum === course.raum)) {
              courseExists.events.push({
                beginn: course.beginn,
                ende: course.ende,
                raum: course.raum,
                raum_en: course.raum_en,
              });
            }
          });

          lvPlFavDBCopy.sort((a, b) => a.lv_tid - b.lv_tid);

          setLvPlFavDB(lvPlFavDBCopy);

          setTabPrioAndFavAndSearchRes('tabFav');

          setXhrload(false);
        }

        if (!response.data) setErrorGetSavedLvPlFavsDB(true);

        setXhrload(false);
      } catch (e) {
        dispatch({
          type: 'set_errcode',
          errcode: e,
        });
        setXhrload(false);
      }
    }
  };

  useEffect(() => {
    getSavedLvPlFavsDB();
  }, []);

  useEffect(() => {
    getSavedLvPlFavsDB();
  }, [currentStudy]);

  useEffect(() => {
    if (searchText && searchText.trim() !== '' && !xhrload &&
      // change semester will VOID the cached result
      courseSearchResult.semester === currentSemester.id) {
      sessionStorage.removeItem('courseSearchText');
    } else {
      dispatch({
        type: 'set_course_search_result',
        courseSearchResult: {
          searchText: '',
          semester: 0,
          courses: [],
        },
      });
    }
  }, []);

  useEffect(() => {
    if (searchResultLoad && searchText.trim() !== '' && !xhrload) {
      loadCourses(searchText);
    }
  }, [currentSemester.id, searchText, searchResultLoad, xhrload]);

  useEffect(() => {
    if (courseSearchResult.semester && courseSearchResult.semester !== currentSemester.id) {
      setSearchText('');

      dispatch({
        type: 'set_course_search_result',
        courseSearchResult: {
          searchText: '',
          semester: 0,
          courses: [],
        },
      });
    }
  }, [currentSemester.id]);

  const handleKeyDown = (event) => {
    if (searchText.length > 2 && searchText.trim() !== '' && !searchResultLoad && event.key === 'Enter') {
      if (!isNaN(searchText) && searchText.length === 3) {
        setSearchText('0' + searchText);
      }

      setSearchResultLoad(true);
      event.target.value = '';
    }
  };

  const handleSubmitClick = (event) => {
    event.preventDefault();
    switch (event.target.id) {
      case 'submit-input':
      case 'search-course-magnifier':
        if (searchText.length > 2 && searchText.trim() !== '' && !searchResultLoad) {
          if (!isNaN(searchText) && searchText.length === 3) {
            setSearchText('0' + searchText);
          }

          setSearchResultLoad(true);
        }
        break;
      case 'reset-input':
        setSearchResultLoad(false);

        setSearchText('');

        setIsFilterCollapsed(false);

        setIsCollisionFilteredOut(false);

        setCourseTypes([]);
        setSelectedCourseType(_('app.lvplaner.filter_all'));

        setCourseLanguages([]);
        setSelectedCourseLanguage(_('app.lvplaner.filter_all'));

        setCourseModes([]);
        setSelectedCourseMode(_('app.lvplaner.filter_all'));

        setIsMonChecked(true);
        setIsTueChecked(true);
        setIsWedChecked(true);
        setIsThuChecked(true);
        setIsFriChecked(true);
        setIsSatChecked(true);

        setIsMorningChecked(true);
        setIsAfternoonChecked(true);
        setIsEveningChecked(true);

        dispatch({
          type: 'set_lv_planer_search_result',
          lvPlSearchResultStored: [],
        });

        dispatch({
          type: 'set_course_search_result',
          courseSearchResult: {
            searchText: '',
            semester: 0,
            courses: [],
          },
        });
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  const loadCourses = async (searchText) => {
    setXhrload(true);

    dispatch({
      type: 'set_lv_planer_search_result',
      lvPlSearchResultStored: [],
    });

    try {
      const response = await BA.ajax({
        url: BA.buildApiUrl(URL_TYPES.COURSE_SEARCH, {text: searchText, semester: currentSemester.id}),
        bpoapi: true,
      });

      if (response.data && response.data.courses) {
        let responseCourses = [];

        responseCourses = response.data.courses.map(
            ({verid,
              lvtyp_1,
              titel_en,
              titel_de,
              teachers,
              wochenstunden_neu}) =>
              (verid = verid.toString().padStart(4, '0'),
              {
                verid,
                lvtyp_1,
                titel_en,
                titel_de,
                teachers,
                wochenstunden_neu,
              }));

        dispatch({
          type: 'set_course_search_result',
          courseSearchResult: {
            searchText: searchText,
            semester: currentSemester.id,
            courses: responseCourses,
          },
        });

        responseCourses.forEach((course) => {
          getCourseDetails(course.verid);
        });
      }

      if (!response.data.courses || response.data.courses.length === 0) {
        dispatch({
          type: 'set_course_search_result',
          courseSearchResult: {
            searchText: '',
            semester: 0,
            courses: [],
          },
        });

        setSearchText('');
        setTabPrioAndFavAndSearchRes('tabSearchRes');

        setSearchResultLoad(false);
        setXhrload(false);
        return;
      }
    } catch (e) {
      dispatch({
        type: 'set_errcode',
        errcode: e,
      });
      setXhrload(false);
    }
  };

  const getCourseDetails = async (courseVerid) => {
    if (!currentSemester.id || typeof currentSemester.id === 'undefined') return;

    if (!xhrload) {
      setXhrload(true);

      setErrorGetCourseDetails(false);
      setLvPlSearchResultsDetails([]);

      try {
        const response = await BA.ajax({
          url: BA.buildApiUrl(URL_TYPES.COURSE_DETAILS, {id: courseVerid, semester: currentSemester.id}),
          bpoapi: true,
        });

        if (response.data) {
          setLvPlSearchResultsDetails((prevCourses) => [...prevCourses, response.data]);
        }

        if (!response.data) setErrorGetCourseDetails(true);

        setSearchText('');
        setTabPrioAndFavAndSearchRes('tabSearchRes');

        setSearchResultLoad(false);
        setXhrload(false);
      } catch (e) {
        dispatch({
          type: 'set_errcode',
          errcode: e,
        });
      }
    }
  };

  useEffect(() => {
    if (lvPlSearchResultsDetails.length > 0) {
      dispatch({
        type: 'set_lv_planer_search_result',
        lvPlSearchResultStored: lvPlSearchResultsDetails,
      });
    }
  }, [lvPlSearchResultsDetails]);

  const filteredSearchResults = useMemo(() => {
    let filteredResults = lvPlSearchResultStored;

    if (isCollisionFilteredOut) {
      const collisionLvNrCopy = [];

      for (const values of Object.values(collisionLvNr)) {
        values.forEach((value) => collisionLvNrCopy.push(value.toString()));
      }

      filteredResults = filteredResults.filter((searchResult) =>
        !collisionLvNrCopy.includes(searchResult.courses.course_info.verid),
      );
    }

    if (selectedCourseType !== _('app.lvplaner.filter_all')) {
      filteredResults = filteredResults.filter((result) =>
        result.courses.course_info.lvtyp_1 === selectedCourseType,
      );
    }

    if (selectedCourseLanguage !== _('app.lvplaner.filter_all')) {
      filteredResults = filteredResults.filter((result) =>
        (language === LANGUAGES.EN ? result.courses.course_info.sprache_en : result.courses.course_info.sprache_de) === selectedCourseLanguage,
      );
    }

    if (selectedCourseMode !== _('app.lvplaner.filter_all')) {
      filteredResults = filteredResults.filter((result) =>
        result.courses.course_didaktik.filter((didaktik) =>
          didaktik.bezeichnung === selectedCourseMode,
        ).length > 0,
      );
    }

    if (!isMonChecked) {
      filteredResults = filteredResults.filter((result) =>
        !result.courses.events.filter((event) =>
          new Date(event.start_time).getDay() === 1,
        ).length > 0,
      );
    }

    if (!isTueChecked) {
      filteredResults = filteredResults.filter((result) =>
        !result.courses.events.filter((event) =>
          new Date(event.start_time).getDay() === 2,
        ).length > 0,
      );
    }

    if (!isWedChecked) {
      filteredResults = filteredResults.filter((result) =>
        !result.courses.events.filter((event) =>
          new Date(event.start_time).getDay() === 3,
        ).length > 0,
      );
    }

    if (!isThuChecked) {
      filteredResults = filteredResults.filter((result) =>
        !result.courses.events.filter((event) =>
          new Date(event.start_time).getDay() === 4,
        ).length > 0,
      );
    }

    if (!isFriChecked) {
      filteredResults = filteredResults.filter((result) =>
        !result.courses.events.filter((event) =>
          new Date(event.start_time).getDay() === 5,
        ).length > 0,
      );
    }

    if (!isSatChecked) {
      filteredResults = filteredResults.filter((result) =>
        !result.courses.events.filter((event) =>
          new Date(event.start_time).getDay() === 6,
        ).length > 0,
      );
    }

    if (!isMorningChecked) {
      filteredResults = filteredResults.filter((result) =>
        !result.courses.events.filter((event) =>
          new Date(event.end_time).getHours() <= 12,
        ).length > 0,
      );
    }

    if (!isAfternoonChecked) {
      filteredResults = filteredResults.filter((result) =>
        !result.courses.events.filter((event) =>
          (new Date(event.start_time).getHours() >= 12 && new Date(event.start_time).getHours() < 16) ||
          (new Date(event.end_time).getHours() > 12 && new Date(event.end_time).getHours() <= 16),
        ).length > 0,
      );
    }

    if (!isEveningChecked) {
      filteredResults = filteredResults.filter((result) =>
        !result.courses.events.filter((event) =>
          new Date(event.start_time).getHours() >= 16,
        ).length > 0,
      );
    }

    return filteredResults;
  },
  [
    lvPlSearchResultStored,
    collisionLvNr,
    isCollisionFilteredOut,
    selectedCourseType,
    selectedCourseLanguage,
    selectedCourseMode,
    isMonChecked,
    isTueChecked,
    isWedChecked,
    isThuChecked,
    isFriChecked,
    isSatChecked,
    isMorningChecked,
    isAfternoonChecked,
    isEveningChecked,
  ]);

  // Save course types for filtering
  useEffect(() => {
    const uniqueCourseTypes = [];

    filteredSearchResults.forEach((result) => {
      if (!uniqueCourseTypes.includes(result.courses.course_info.lvtyp_1)) {
        uniqueCourseTypes.push(result.courses.course_info.lvtyp_1);
      }
    });

    setCourseTypes([_('app.lvplaner.filter_all'), ...uniqueCourseTypes]);
  }, [filteredSearchResults]);

  // Save course languages for filtering
  useEffect(() => {
    const uniqueCourseLanguages = [];

    filteredSearchResults.forEach((result) => {
      if (!uniqueCourseLanguages.includes(language === LANGUAGES.EN ? result.courses.course_info.sprache_en : result.courses.course_info.sprache_de)) {
        uniqueCourseLanguages.push(language === LANGUAGES.EN ? result.courses.course_info.sprache_en : result.courses.course_info.sprache_de);
      }
    });

    setCourseLanguages([_('app.lvplaner.filter_all'), ...uniqueCourseLanguages]);
  }, [filteredSearchResults]);

  // Save course modes for filtering
  useEffect(() => {
    const uniqueCourseModes = [];

    filteredSearchResults.forEach((result) => {
      result.courses.course_didaktik.forEach((element) => {
        if (!uniqueCourseModes.includes(element.bezeichnung)) {
          uniqueCourseModes.push(element.bezeichnung);
        }
      });
    });

    setCourseModes([_('app.lvplaner.filter_all'), ...uniqueCourseModes]);
  }, [filteredSearchResults]);

  const saveLvPlFavDB = async (courseInfos) => {
    if (currentStudy.id === -1) {
      setInfoCurrentStudyMissing(true);
      return;
    }

    const courseExists = lvPlFavDB.find((course) => courseInfos.verid === course.lv_nr);

    if (courseExists) {
      setSearchText('');
      setTabPrioAndFavAndSearchRes('tabFav');

      dispatch({
        type: 'set_lv_planer_search_result',
        lvPlSearchResultStored: [],
      });
      return;
    }

    if (!xhrload) {
      setXhrload(true);

      setErrorSaveLvPlFavDB(false);
      setInfoCurrentStudyMissing(false);

      try {
        const response = await BA.ajax({
          url: BA.buildApiUrl(URL_TYPES.SAVE_LV_PL_FAV),
          bpoapi: true,
          method: 'post',
          contentType: 'application/json',
          data: JSON.stringify({study: currentStudy.id, lv: courseInfos.tid}),
        });

        if (response.data) await getSavedLvPlFavsDB();

        if (!response.data) setErrorSaveLvPlFavDB(true);

        setXhrload(false);
      } catch (e) {
        dispatch({
          type: 'set_errcode',
          errcode: e,
        });
        setXhrload(false);
      }
    }
  };

  const deleteLvPlFavDB = async (course) => {
    if (currentStudy.id === -1) {
      setInfoCurrentStudyMissing(true);
      return;
    }

    if (!xhrload) {
      setXhrload(true);

      setErrorDeleteLvPlFavDB(false);
      setInfoCurrentStudyMissing(false);

      try {
        const response = await BA.ajax({
          url: BA.buildApiUrl(URL_TYPES.DELETE_LV_PL_FAV),
          bpoapi: true,
          method: 'delete',
          contentType: 'application/json',
          data: JSON.stringify({study: currentStudy.id, lv: course.lv_tid}),
        });

        if (response.data) await getSavedLvPlFavsDB();

        if (!response.data) setErrorDeleteLvPlFavDB(true);

        setXhrload(false);
      } catch (e) {
        dispatch({
          type: 'set_errcode',
          errcode: e,
        });
        setXhrload(false);
      }
    }
  };

  const checkCollision = (searchCourseVerid, searchEventStart, searchEventEnd) => {
    const searchVerid = searchCourseVerid.padStart(4, '0');

    const searchStart = new Date(searchEventStart).getTime();
    const searchEnd = new Date(searchEventEnd).getTime();

    let collisionFound = false;
    const newCollisionLvNr = {...collisionLvNr};

    lvPlFavDB.forEach((course) => {
      if (course.lv_nr === searchVerid) return;

      course.events.forEach((courseEvent) => {
        const savedStart = new Date(courseEvent.beginn).getTime();
        const savedEnd = new Date(courseEvent.ende).getTime();

        if (BA.checkCollision(searchStart, searchEnd, savedStart, savedEnd)) {
          // If this course LV_Nr isn't already in the collisions, add it with an empty array
          if (!newCollisionLvNr[course.lv_nr]) {
            newCollisionLvNr[course.lv_nr] = [];
          }

          // Add the search course LV_Nr to the list of conflicting courses if not already present
          if (!newCollisionLvNr[course.lv_nr].includes(searchVerid)) {
            newCollisionLvNr[course.lv_nr].push(searchVerid);

            newCollisionLvNr[course.lv_nr].sort((a, b) => a - b);
          }

          collisionFound = true;
        }
      });
    });

    // Update collisionLvNr state if necessary
    if (Object.keys(newCollisionLvNr).length !== Object.keys(collisionLvNr).length) {
      setCollisionLvNr(newCollisionLvNr);
    }

    return collisionFound;
  };

  useEffect(() => {
    lvPlFavDB.forEach((course) => {
      course.events.forEach((event) => {
        checkCollision(course.lv_nr, event.beginn, event.ende);
      });
    });
  }, [lvPlFavDB]);

  const processLvPlFavsForSemCal = (lvPlFavDB) => {
    const dateGroupedLvPlFavsForSemCal = [];

    lvPlFavDB.forEach((course) => {
      course.events.forEach((event) => {
        const startDate = new Date(event.beginn);
        const startDateFormatted = BA.printCurrentDayString(startDate, weekdays);
        const endDate = new Date(event.ende);

        let dateGroupedLvPlFavs = dateGroupedLvPlFavsForSemCal.find((group) => group.startDateFormatted === startDateFormatted);

        if (!dateGroupedLvPlFavs) {
          dateGroupedLvPlFavs = {
            startDateFormatted: startDateFormatted,
            rawStartDate: startDate,
            courses: [],
          };
          dateGroupedLvPlFavsForSemCal.push(dateGroupedLvPlFavs);
        }

        // Add the course to the date group
        dateGroupedLvPlFavs.courses.push({
          lv_nr: course.lv_nr,
          titel: course.titel,
          titel_en: course.titel_en,
          startTime: BA.getTimeString(event.beginn),
          rawStartTime: startDate,
          endTime: BA.getTimeString(event.ende),
          rawEndTime: endDate,
          semester: course.semester,
          raum: event.raum,
          raum_en: event.raume_en,
          hasCollision: false,
        });
      });
    });

    dateGroupedLvPlFavsForSemCal.sort((a, b) => a.rawStartDate - b.rawStartDate);

    dateGroupedLvPlFavsForSemCal.forEach((group) => {
      group.courses.sort((a, b) => a.rawStartTime - b.rawStartTime);

      // Check for collisions between the courses
      for (let i = 0; i < group.courses.length - 1; i++) {
        const course1 = group.courses[i];
        const course2 = group.courses[i + 1];

        if (BA.checkCollision(course1.rawStartTime, course1.rawEndTime, course2.rawStartTime, course2.rawEndTime)) {
          course1.hasCollision = true;
          course2.hasCollision = true;
        }
      }
    });

    return dateGroupedLvPlFavsForSemCal;
  };

  const dateGroupedLvPlFavsForSemCal = processLvPlFavsForSemCal(lvPlFavDB);

  return (
    <Col className="p-0">
      <Row className="px-4 pt-4 g-0">
        <div className="lv-planer col-12 offset-lg-1 col-lg-6 col-xxl-6 ">

          <UI.PageTitle backurl="goback">{_('app.lvplaner.title')}</UI.PageTitle>

          {/* Must be a student to see LV-Planer */}
          {userInfo.student ? (
            <>

              {/* Semester List */}
              <div className=" d-lg-none"><SemesterList /></div>

              {/* Study Selection */}
              <Row className="mt-4">
                <Col>
                  <h5 className='pt-3'>{_('app.lvplaner.study_selection')}</h5>
                </Col>
                <Col className="text-end col-6 col-sm-6 col-md-6 col-lg-5">
                  <UI.StudyList hideHeader={true} variantStudyProgram={false} variantLvPlaner={true} filteredStudiesForLvPlaner={filteredStudiesForLvPl}/>
                </Col>
              </Row>

              {/* Info Box */}
              <div className='mt-4 mb-4'>
                <UI.InfoBox>
                  <UI.InfoText>
                    {_('app.lvplaner.lv_planer_tip')} {_('app.lvplaner.lv_planer_tip_description')}
                  </UI.InfoText>
                  <UI.InfoText>
                    {_('app.lvplaner.search_tip')} {_('app.lvplaner.search_tip_description')}
                  </UI.InfoText>
                </UI.InfoBox>
              </div>

              {/* Error Box */}
              <div className='mt-2 mb-2'>
                { ( errorGetSavedLvPlFavsDB ||
                    errorGetCourseDetails ||
                    errorSaveLvPlFavDB ||
                    errorDeleteLvPlFavDB) && (
                  <UI.InfoBox>
                    <div className='error-message'>
                      {errorGetSavedLvPlFavsDB && (
                        <>
                          <UI.Icon icon="bi-error bachicon-inline me-1" />
                          {_('app.lvplaner.error_get_saved_lv_pl_favs_db')}
                          <br />
                        </>
                      )}
                      {errorGetCourseDetails && (
                        <>
                          <UI.Icon icon="bi-error bachicon-inline me-1" />
                          {_('app.lvplaner.error_get_course_details')}
                          <br />
                        </>
                      )}
                      {errorSaveLvPlFavDB && (
                        <>
                          <UI.Icon icon="bi-error bachicon-inline me-1" />
                          {_('app.lvplaner.error_save_lv_pl_fav_db')}
                          <br />
                        </>
                      )}
                      {errorDeleteLvPlFavDB && (
                        <>
                          <UI.Icon icon="bi-error bachicon-inline me-1" />
                          {_('app.lvplaner.error_delete_lv_pl_fav_db')}
                          <br />
                        </>
                      )}
                    </div>
                  </UI.InfoBox>
                )}
              </div>

              {/* Tab Search + Filter OR Tab Search Sug */}
              <Row>
                <div className='container'>
                  <Tab.Container activeKey={tabSearchAndSearchSug} onSelect={(key) => setTabSearchAndSearchSug(key)}>
                    <Nav variant="tabs" className="nav nav-tabs">
                      <div className="d-flex justify-content-between w-100">
                        <div className="d-flex">
                          <Nav.Item>
                            <Nav.Link eventKey="tabSearch">{_('app.lvplaner.tab_search')}</Nav.Link>
                          </Nav.Item>
                        </div>
                        <div className="d-flex">
                          <Nav.Item>
                            <Button
                              onClick={() => setIsTabSearchAndSearchSugCollapsed(!isTabSearchAndSearchSugCollapsed)}
                              className={isTabSearchAndSearchSugCollapsed ? 'btn bi-arrow-up-square' : 'btn bi-arrow-down-square'}
                            ></Button>
                          </Nav.Item>
                        </div>
                      </div>
                    </Nav>

                    {!isTabSearchAndSearchSugCollapsed&& (
                      <Tab.Content>
                        {/* Tab Search */}
                        <Tab.Pane eventKey="tabSearch">
                          <Card className="card mt-2">
                            <Card.Header className="card-header p-2">
                              {_('app.course_search.search_for_courses')}
                            </Card.Header>
                            <Card.Body className="card-body py-3">
                              <p className="m-0 mb-1">{_('app.course_search.course_information')}</p>
                              <input
                                type="text"
                                name="search"
                                placeholder={_('general.search')}
                                id="search_course"
                                className="form-control shadow-sm"
                                onChange={handleChange}
                                onKeyDown={handleKeyDown}
                                value={searchText}
                                disabled={xhrload || currentSemester.title === ''} />
                              <Search
                                id="search-course-magnifier"
                                className="magnifier"
                                onClick={handleSubmitClick}
                              />
                            </Card.Body>
                          </Card>

                          {/* Filter Button, Label and Options */}
                          <div>
                            {/* Filter Button & Label */}
                            <Row>
                              <Col className="mt-3 mb-1 filter-option">
                                <button
                                  className={'btn bi-filter'}
                                  onClick={() => setIsFilterCollapsed(!isFilterCollapsed)}
                                ></button>
                                <label
                                  className="filter-option-label ms-2"
                                  onClick={() => setIsFilterCollapsed(!isFilterCollapsed)}
                                >{_('app.lvplaner.filter_title')}</label>
                              </Col>
                            </Row>

                            {/* Filter Options */}
                            {isFilterCollapsed && (
                              <div className="mt-2 filter">

                                <div className="filter-section">
                                  {/* Filter Collisions */}
                                  <Row className="mb-2">
                                    <Col xs={10} md={6}>
                                      <label>{_('app.lvplaner.filter_collision_free')}</label>
                                    </Col>
                                    <Col xs={2} md={6}>
                                      <Form>
                                        <Form.Check
                                          className='filter-switch'
                                          type="switch"
                                          checked={isCollisionFilteredOut}
                                          onChange={(e) => setIsCollisionFilteredOut(e.target.checked)}
                                        />
                                      </Form>
                                    </Col>
                                  </Row>

                                </div>

                                <div className="filter-section">
                                  {/* Filter Course Types */}
                                  <Row className="mb-2">
                                    <Col xs={12} md={6}>
                                      <label className="mt-1">{_('app.lvplaner.filter_course_type')}</label>
                                    </Col>
                                    <Col xs={12} md={6}>
                                      <Form.Select
                                        className='smallselect filter_select'
                                        value={selectedCourseType}
                                        onChange={(e) => setSelectedCourseType(e.target.value)}
                                      >
                                        {courseTypes.map((courseType, courseTypeIndex) => (
                                          <option key={courseTypeIndex} value={courseType}>
                                            {courseType}
                                          </option>
                                        ))}
                                      </Form.Select>
                                    </Col>
                                  </Row>

                                  {/* Filter Course Languages */}
                                  <Row className="mb-2">
                                    <Col xs={12} md={6}>
                                      <label className="mt-1">{_('app.lvplaner.filter_course_language')}</label>
                                    </Col>
                                    <Col xs={12} md={6}>
                                      <Form.Select
                                        className='smallselect filter_select'
                                        value={selectedCourseLanguage}
                                        onChange={(e) => setSelectedCourseLanguage(e.target.value)}
                                      >
                                        {courseLanguages.map((courseLanguage, courseLanguageIndex) => (
                                          <option key={courseLanguageIndex} value={courseLanguage}>
                                            {courseLanguage}
                                          </option>
                                        ))}
                                      </Form.Select>
                                    </Col>
                                  </Row>

                                  {/* Filter Course Modes */}
                                  <Row className="mb-1">
                                    <Col xs={12} md={6}>
                                      <label className="mt-1">{_('app.lvplaner.filter_course_mode')}</label>
                                    </Col>
                                    <Col xs={12} md={6}>
                                      <Form.Select
                                        className='smallselect filter_select'
                                        value={selectedCourseMode}
                                        onChange={(e) => setSelectedCourseMode(e.target.value)}
                                      >
                                        {courseModes.map((courseMode, courseModeIndex) => (
                                          <option key={courseModeIndex} value={courseMode}>
                                            {courseMode}
                                          </option>
                                        ))}
                                      </Form.Select>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="filter-section">
                                  {/* Filter Days */}
                                  <Row className='mb-3'>
                                    <Col xs={12} md={6}>
                                      <label>{_('app.lvplaner.filter_days')}</label>
                                    </Col>
                                    <Col xs={12} md={6}>
                                      <div className="checkbox-group-days">
                                        <div>
                                          <input
                                            className='filter-checkbox'
                                            type="checkbox"
                                            checked={isMonChecked}
                                            onChange={(e) => setIsMonChecked(e.target.checked)}
                                          /> {_('schedule.mon')}
                                        </div>
                                        <div>
                                          <input
                                            className='filter-checkbox'
                                            type="checkbox"
                                            checked={isTueChecked}
                                            onChange={(e) => setIsTueChecked(e.target.checked)}
                                          /> {_('schedule.tue')}
                                        </div>
                                        <div>
                                          <input
                                            className='filter-checkbox'
                                            type="checkbox"
                                            checked={isWedChecked}
                                            onChange={(e) => setIsWedChecked(e.target.checked)}
                                          /> {_('schedule.wed')}
                                        </div>
                                        <div>
                                          <input
                                            className='filter-checkbox'
                                            type="checkbox"
                                            checked={isThuChecked}
                                            onChange={(e) => setIsThuChecked(e.target.checked)}
                                          /> {_('schedule.thu')}
                                        </div>
                                        <div>
                                          <input
                                            className='filter-checkbox'
                                            type="checkbox"
                                            checked={isFriChecked}
                                            onChange={(e) => setIsFriChecked(e.target.checked)}
                                          /> {_('schedule.fri')}
                                        </div>
                                        <div>
                                          <input
                                            className='filter-checkbox'
                                            type="checkbox"
                                            checked={isSatChecked}
                                            onChange={(e) => setIsSatChecked(e.target.checked)}
                                          /> {_('schedule.sat')}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>

                                  {/* Filter Times */}
                                  <Row className=''>
                                    <Col xs={12} md={6}>
                                      <label>{_('app.lvplaner.filter_times')}</label>
                                    </Col>
                                    <Col xs={12} md={6}>
                                      <div className="checkbox-group-times">
                                        <div className='mb-1'>
                                          <input
                                            className='filter-checkbox'
                                            type="checkbox"
                                            checked={isMorningChecked}
                                            onChange={(e) => setIsMorningChecked(e.target.checked)}
                                          /> {_('app.lvplaner.filter_times_morning')}
                                        </div>
                                        <div className='mb-1'>
                                          <input
                                            className='filter-checkbox'
                                            type="checkbox"
                                            checked={isAfternoonChecked}
                                            onChange={(e) => setIsAfternoonChecked(e.target.checked)}
                                          /> {_('app.lvplaner.filter_times_afternoon')}
                                        </div>
                                        <div className='mb-1'>
                                          <input
                                            className='filter-checkbox'
                                            type="checkbox"
                                            checked={isEveningChecked}
                                            onChange={(e) => setIsEveningChecked(e.target.checked)}
                                          /> {_('app.lvplaner.filter_times_evening')}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>

                              </div>
                            )}
                          </div>

                          {/* Search Button & Reset Button */}
                          <Row className="g-2 my-2 d-flex justify-content-center">
                            <Col className="d-grid">
                              <Button
                                type="submit"
                                id="submit-input"
                                variant="primary"
                                onClick={handleSubmitClick}
                              >
                                {_('app.course_search.search_button')}
                              </Button>
                            </Col>
                            <Col xs={5} sm={4} className="d-grid">
                              <Button
                                type="submit"
                                id="reset-input"
                                variant="secondary"
                                onClick={handleSubmitClick}
                              >
                                {_('app.course_search.reset_button')}
                              </Button>
                            </Col>
                          </Row>
                        </Tab.Pane>
                      </Tab.Content>
                    )}
                  </Tab.Container>
                </div>
              </Row>

              {/* Tab Prio OR Tab Fav OR Tab Search Res */}
              <Row>
                <div className='container mt-4'>
                  <Tab.Container activeKey={tabPrioAndFavAndSearchRes} onSelect={(key) => setTabPrioAndFavAndSearchRes(key)}>
                    <Nav variant="tabs" className="nav nav-tabs">
                      <div className="d-flex justify-content-between w-100">
                        <div className="d-flex">
                          <Nav.Item>
                            <Nav.Link eventKey="tabFav">{_('app.lvplaner.tab_fav')}</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="tabSearchRes">{_('app.lvplaner.tab_search_res')}</Nav.Link>
                          </Nav.Item>
                        </div>
                        <div className="d-flex">
                          <Nav.Item>
                            <Button
                              onClick={() => setIsTabPrioAndFavAndSearchResCollapsed(!isTabPrioAndFavAndSearchResCollapsed)}
                              className={isTabPrioAndFavAndSearchResCollapsed ? 'btn bi-arrow-up-square' : 'btn bi-arrow-down-square'}>
                            </Button>
                          </Nav.Item>
                        </div>
                      </div>
                    </Nav>

                    {!isTabPrioAndFavAndSearchResCollapsed && (
                      <Tab.Content>
                        {/* Tab Fav */}
                        <Tab.Pane eventKey="tabFav">
                          <div className='mt-3 mb-0 text-end'>
                            <Badge bg="highlight">
                              {currentStudy.studyCode === 'all' ? _('app.lvplaner.study_selection_select') : currentStudy.studyCode}
                            </Badge>
                          </div>

                          {infoCurrentStudyMissing && (
                            <div>
                              <UI.InfoBox>
                                <UI.InfoText>
                                  {_('app.lvplaner.info_current_study_missing')}
                                </UI.InfoText>
                              </UI.InfoBox>
                            </div>
                          )}

                          {xhrload && <UI.AjaxLoading />}
                          {/* Table Fav */}
                          {(!xhrload && lvPlFavDB != null) &&
                              <UI.BachDataTable
                                noDataComponent={
                                  <div className='no-data'>
                                    <UI.InfoBox>
                                      <UI.InfoText>
                                        {_('app.lvplaner.info_no_data_fav_table')}
                                      </UI.InfoText>
                                    </UI.InfoBox>
                                  </div>
                                }
                                hideCount={true}
                                hideFilter={true}
                                columns={columnsLvPlFavsDB}
                                data={lvPlFavDB}
                                useExpandableRows={true}
                                expandableRowsComponent={({data}) => (
                                  <div className="expandle-row row mt-3">
                                    <div className="expandable-col-one col-md-6">
                                      <div className="row mb-1">
                                        <div className="col-4 col-md-5">
                                          <p className="card-text">
                                            <strong>{_('app.lvplaner.lv_type')}</strong>
                                          </p>
                                        </div>
                                        <div className="col-4 col-md-5">
                                          <p className="card-text">{data.lv_typ}</p>
                                        </div>
                                      </div>
                                      <div className="row mb-1">
                                        <div className="col-4 col-md-5">
                                          <p className="card-text">
                                            <strong>{_('app.lvplaner.lv_language')}</strong>
                                          </p>
                                        </div>
                                        <div className="col-4 col-md-5">
                                          <p className="card-text">{language === LANGUAGES.EN ? data.lv_sprache_en : data.lv_sprache}</p>
                                        </div>
                                      </div>
                                      <div className="row mb-1">
                                        <div className="col-4 col-md-5">
                                          <p className="card-text">
                                            <strong>{_('app.lvplaner.lv_mode')}</strong>
                                          </p>
                                        </div>
                                        <div className="col-4 col-md-5">
                                          <p className="card-text">{data.lv_didaktik}</p>
                                        </div>
                                      </div>
                                      <div className="row mb-1">
                                        <div className="col-4 col-md-5">
                                          <p className="card-text">
                                            <strong>{_('app.lvplaner.lv_registration')}</strong>
                                          </p>
                                        </div>
                                        <div className="col-4 col-md-5">
                                          <p className="card-text">{BA.printCurrentDayString(data.anmeldung_von, weekdays)}: {BA.getTimeString(data.anmeldung_von)}</p>
                                        </div>
                                      </div>
                                      <div className="row mb-1">
                                        <div className="col-4 col-md-5">
                                          <p className="card-text">
                                            <strong>{_('app.lvplaner.lv_deregistration')}</strong>
                                          </p>
                                        </div>
                                        <div className="col-4 col-md-5">
                                          <p className="card-text">{BA.printCurrentDayString(data.anmeldung_bis, weekdays)}: {BA.getTimeString(data.anmeldung_bis)}</p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="expandable-col-two col-md-6">
                                      <div className="row">
                                        <p className="card-text mb-1">
                                          <strong>{_('app.lvplaner.lv_appointment')}</strong>
                                        </p>
                                        {data.events.map((event, i) => {
                                          const eventCollision = checkCollision(data.lv_nr, event.beginn, event.ende);
                                          return (
                                            <div key={i} className='row mb-1'>
                                              <div className="col-4 col-md-6">
                                                <p className={eventCollision ? 'card-text text-danger' : 'card-text'}>{BA.printCurrentDayString(event.beginn, weekdays)}</p>
                                              </div>
                                              <div className="expandable-col-two-col-two col-4 col-md-6">
                                                <p className={eventCollision ? 'card-text text-danger' : 'card-text'}>{BA.getTimeString(event.beginn)} - {BA.getTimeString(event.ende)}</p>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              />
                          }
                        </Tab.Pane>

                        {/* Tab Search Result */}
                        <Tab.Pane eventKey="tabSearchRes">
                          {infoCurrentStudyMissing && (
                            <div className='mt-3'>
                              <UI.InfoBox>
                                <UI.InfoText>
                                  {_('app.lvplaner.info_current_study_missing')}
                                </UI.InfoText>
                              </UI.InfoBox>
                            </div>
                          )}

                          {xhrload && <UI.AjaxLoading />}
                          {/* Table Search Res */}
                          {(!xhrload && filteredSearchResults !== null) &&
                              <UI.BachDataTable
                                noDataComponent={
                                  <div className='no-data'>
                                    <UI.InfoBox>
                                      <UI.InfoText>
                                        {_('app.lvplaner.info_no_data_search_res_table')}
                                      </UI.InfoText>
                                    </UI.InfoBox>
                                  </div>
                                }
                                hideCount={true}
                                hideFilter={true}
                                columns={columnsLvPlSearchResult}
                                data={filteredSearchResults}
                                useExpandableRows={true}
                                expandableRowsComponent={({data}) => (
                                  <div className="expandle-row row mt-3">
                                    <div className="expandable-col-one col-md-6">
                                      <div className="row mb-1">
                                        <div className="col-4 col-md-5">
                                          <p className="card-text">
                                            <strong>{_('app.lvplaner.lv_type')}</strong>
                                          </p>
                                        </div>
                                        <div className="col-4 col-md-5">
                                          <p className="card-text">{data.courses.course_info.lvtyp_1}</p>
                                        </div>
                                      </div>
                                      <div className="row mb-1">
                                        <div className="col-4 col-md-5">
                                          <p className="card-text">
                                            <strong>{_('app.lvplaner.lv_language')}</strong>
                                          </p>
                                        </div>
                                        <div className="col-4 col-md-5">
                                          <p className="card-text">{language === LANGUAGES.EN ? data.courses.course_info.sprache_en : data.courses.course_info.sprache_de}</p>
                                        </div>
                                      </div>
                                      <div className="row mb-1">
                                        <div className="col-4 col-md-5">
                                          <p className="card-text">
                                            <strong>{_('app.lvplaner.lv_mode')}</strong>
                                          </p>
                                        </div>
                                        <div className="col-4 col-md-5">
                                          <p className="card-text">{data.courses.course_info.didaktik}</p>
                                        </div>
                                      </div>
                                      <div className="row mb-1">
                                        <div className="col-4 col-md-5">
                                          <p className="card-text">
                                            <strong>{_('app.lvplaner.lv_registration')}</strong>
                                          </p>
                                        </div>
                                        <div className="col-4 col-md-5">
                                          <p className="card-text">{BA.printCurrentDayString(data.courses.course_info.anmeldung_von, weekdays)}: {BA.getTimeString(data.courses.course_info.anmeldung_von)}</p>
                                        </div>
                                      </div>
                                      <div className="row mb-1">
                                        <div className="col-4 col-md-5">
                                          <p className="card-text">
                                            <strong>{_('app.lvplaner.lv_deregistration')}</strong>
                                          </p>
                                        </div>
                                        <div className="col-4 col-md-5">
                                          <p className="card-text">{BA.printCurrentDayString(data.courses.course_info.anmeldung_bis, weekdays)}: {BA.getTimeString(data.courses.course_info.anmeldung_bis)}</p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="expandable-col-two col-md-6">
                                      <div className="row">
                                        <p className="card-text mb-1">
                                          <strong>{_('app.lvplaner.lv_appointment')}</strong>
                                        </p>
                                        {data.courses.events.map((event, i) => {
                                          const eventCollision = checkCollision(data.courses.course_info.verid, event.start_time, event.end_time);
                                          return (
                                            <div key={i} className='row mb-1'>
                                              <div className="col-4 col-md-6">
                                                <p className={eventCollision ? 'card-text text-danger' : 'card-text'}>{BA.printCurrentDayString(event.start_time, weekdays)}</p>
                                              </div>
                                              <div className="expandable-col-two-col-two col-4 col-md-6">
                                                <p className={eventCollision ? 'card-text text-danger' : 'card-text'}>{BA.getTimeString(event.start_time)} - {BA.getTimeString(event.end_time)}</p>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              />
                          }
                        </Tab.Pane>
                      </Tab.Content>
                    )}
                  </Tab.Container>
                </div>
              </Row>

              {/* Tab Semester OR Tab Month */}
              <Row>
                <div className='container mt-4'>
                  {lvPlFavDB.length > 0 && (
                    <Tab.Container activeKey={tabSemMonth} onSelect={(key) => setTabSemMonth(key)} >
                      <Nav variant="tabs" className="nav nav-tabs mb-1">
                        <div className="d-flex justify-content-between w-100">
                          <div className="d-flex">
                            <Nav.Item>
                              <Nav.Link eventKey="tabSem">{_('app.lvplaner.tab_semester')}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="tabMonth">{_('app.lvplaner.tab_month')}</Nav.Link>
                            </Nav.Item>
                          </div>
                          <div className="d-flex">
                            <Nav.Item>
                              <Button
                                onClick={() => setIsTabSemMonthCollapsed(!isTabSemMonthCollapsed)}
                                className={isTabSemMonthCollapsed ? 'btn bi-arrow-up-square' : 'btn bi-arrow-down-square'}>
                              </Button>
                            </Nav.Item>
                          </div>
                        </div>
                      </Nav>

                      {!isTabSemMonthCollapsed && (
                        <Tab.Content>
                          {/* Tab Semester */}
                          <Tab.Pane eventKey="tabSem">
                            {/* Table Semester */}
                            <UI.BachDataTable
                              noDataComponent={_('app.course_search.no_course_found')}
                              hideCount={true}
                              hideFilter={true}
                              columns={columnsLvPlFavsDBSemCal}
                              data={dateGroupedLvPlFavsForSemCal}
                            />
                          </Tab.Pane>

                          {/* Tab Month */}
                          <Tab.Pane eventKey="tabMonth">
                            {/* Calendar Month */}
                            <Calendar variant="monthLVPlaner" lvPlFavDB={lvPlFavDB} />
                          </Tab.Pane>
                        </Tab.Content>
                      )}
                    </Tab.Container>
                  )}
                </div>
              </Row>

            </>

          /* If not a student, then Infotext is displayed */
          ) : (
            <div className='pt-4 pb-4'>
              <UI.InfoBox>
                <UI.InfoText>
                  {_('app.lvplaner.not_student_tip')} {_('app.lvplaner.not_student_tip_description')}
                </UI.InfoText>
              </UI.InfoBox>
            </div>
          )}

        </div>
        <div className="col-12 col-lg-3 offset-lg-1 col-xxl-3 d-none d-lg-block">
          <MyFavorites showedittile />
        </div>
      </Row>
    </Col>
  );
}

export default LVPlaner;
