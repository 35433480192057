module.exports = Object.freeze({
  THEMES: [ // attention change list of themes also in public/bach-load-theme.js
    {'id': 1, 'name': 'standard'},
    {'id': 2, 'name': 'dark'},
    {'id': 3, 'name': 'corporate'},
  ],
  THEME_STANDARD: 1,
  THEME_DARK: 2,
  THEME_CORPORATE: 3,
  URL_TYPES: {
    COURSE_SEARCH: 'COURSE_SEARCH',
    COURSE_DETAILS: 'COURSE_DETAILS',
    COURSE_INSTRUCTORS: 'COURSE_INSTRUCTORS',
    EVENTS_MONTH: 'EVENTS_MONTH',
    EVENTS_WEEK: 'EVENTS_WEEKS',
    EXAMS: 'EXAMS',
    GRADES: 'GRADES',
    MY_COURSES: 'MY_COURSES',
    TILES: 'TILES',
    UPLOAD_AVATAR: 'UPLOAD_AVATAR',
    DELETE_AVATAR: 'DELETE_AVATAR',
    USER_INFO: 'USER_INFO',
    USER_PROFILE: 'USER_PROFILE',
    THEME: 'THEME',
    SEMESTERS: 'SEMESTERS',
    COUNTRIES: 'COUNTRIES',
    DELIVERY_ADDRESS: 'DELIVERY_ADDRESS',
    LOG_ERROR: 'LOG_ERROR',
    ACCOUNT_PASSWORD: 'ACCOUNT_PASSWORD',
    WLAN_PASSWORD: 'WLAN_PASSWORD',
    ACCEPT_POLICY: 'ACCEPT_POLICY',
    OFFICE365_CONFIG: 'OFFICE365_CONFIG',
    RESET_PW_SEND_CODE: 'RESET_PW_SEND_CODE',
    VERIFY_PW_RESET: 'VERIFY_PW_RESET',
    STUDY_PROGRAMS: 'STUDY_PROGRAMS',
    MIN_REQUIREMENT: 'MIN_REQUIREMENT',
    MIN_REQUIREMENT_EXEMPTION: 'MIN_REQUIREMENT_EXEMPTION',
    USER_MAPPING: 'USER_MAPPING',
    NOTIFICATIONS: 'NOTIFICATIONS',
    NOTIFICATION: 'NOTIFICATION',
    ADMIN_NOTIFICATIONS: 'ADMIN_NOTIFICATIONS',
    NOTIFICATION_TYPE: 'NOTIFICATION_TYPE',
    NOTIFICATION_GROUP: 'NOTIFICATION_GROUP',
    THESIS_MANAGEMENT: 'THESIS_MANAGEMENT',
    THESIS_DETAILS: 'THESIS_DETAILS',
    GET_STUDY_SECTIONS: 'GET_STUDY_SECTIONS',
    GET_SAVED_LV_PL_FAVS: 'GET_SAVED_LV_PL_FAVS',
    SAVE_LV_PL_FAV: 'SAVE_LV_PL_FAV',
    DELETE_LV_PL_FAV: 'DELETE_LV_PL_FAV',
    GET_REPTL_OPEN_COURSES: 'GET_REPTL_OPEN_COURSES',
    IS_INCOMING_STUDY: 'IS_INCOMING_STUDY',
    MAX_PARTICIPANT_SK: 'MAX_PARTICIPANT_SK',
  },
  LANGUAGES: {
    EN: 'en',
    DE: 'de',
  },
  DEFAULT_LANG: 'DE',
  DEBUGMODE: process.env.REACT_APP_ENV==='PROD'||
             process.env.REACT_APP_ENV==='QA'? false: true,
});
